import React, { useState } from 'react';
import { Box, TextField, Button, Card, CardContent, IconButton, Typography, MenuItem, Switch, FormControlLabel, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { districts } from '../user/Districs';
import axios from 'axios';
import { format } from 'date-fns';

const EditionCard = ({ deliveryPoint, onClose }) => {
    const [formData, setFormData] = useState({ ...deliveryPoint });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSave = async () => {
        try {

            // Format the delivery_date to match the expected format
            const formattedData = {
                ...formData,
                delivery_date: formData.delivery_date
                    ? format(new Date(formData.delivery_date), 'yyyy-MM-dd')
                    : formData.delivery_date,
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/update/delivery_point`, formattedData);
            console.log('Data saved successfully:', formattedData);
            onClose();  // Close the edit card after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Card sx={{ maxWidth: 250, position: 'absolute', top: "80px", left: "620px", maxHeight: "380px", overflowY: 'auto', }}>
            <CardContent>
                <Typography variant="h6">Edita Entrega</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="User ID"
                        name="user_id"
                        value={formData.user_id || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Recipient Name"
                        name="recipient_name"
                        value={formData.recipient_name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Address"
                        name="address"
                        value={formData.address || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Latitude"
                        name="latitude"
                        value={formData.latitude || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Longitude"
                        name="longitude"
                        value={formData.longitude || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Recipient Phone"
                        name="recipient_phone"
                        value={formData.recipient_phone || ''}
                        onChange={handleInputChange}
                        type="tel"
                    />
                    <TextField
                        label="Notes"
                        name="notes"
                        value={formData.notes || ''}
                        onChange={handleInputChange}
                    />
                    <Select
                        label="District"
                        name="district"
                        value={formData.district || ''}
                        onChange={handleInputChange}
                        displayEmpty
                        style={{ width: '100%' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 400, // Adjust as necessary for your layout
                                    overflow: 'auto',
                                    position: 'absolute',
                                    left: '50%', // Position it next to the EditionCard
                                    top: '80px',
                                    zIndex: 2500,
                                    paddingLeft: '40px',
                                },
                            },
                        }}
                    >
                        {Object.keys(districts).map((districtName) => (
                            <MenuItem key={districtName} value={districtName}>
                                {districtName}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Status"
                        name="status"
                        value={formData.status || ''}
                        onChange={handleInputChange}
                        select
                        style={{ width: '100%' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    position: 'absolute',
                                    right: '50%',
                                    top: '80px',
                                    zIndex: 2500,
                                    paddingLeft: '40px',
                                },
                            },
                        }}
                    >
                        <MenuItem value="pending">Pendiente</MenuItem>
                        <MenuItem value="encamino">En camino</MenuItem>
                        <MenuItem value="entregado">Entregado</MenuItem>
                        <MenuItem value="pospuesto">Pospuesto</MenuItem>
                        <MenuItem value="cancelado">Cancelado</MenuItem>
                    </TextField>
                    <TextField
                        label="Charge"
                        name="charge"
                        value={formData.charge || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label=""
                        name="delivery_date"
                        value={formData.delivery_date || ''}
                        onChange={handleInputChange}
                        type="datetime-local"
                    />
                    <TextField
                        label="Driver ID"
                        name="driver_id"
                        value={formData.driver_id || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Secuencia"
                        name="sequence"
                        value={formData.sequence || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Charged"
                        name="charged"
                        value={formData.charged || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.transfer || false}
                                onChange={handleInputChange}
                                name="transfer"
                            />
                        }
                        label="Transfer"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.transfer_client || false}
                                onChange={handleInputChange}
                                name="transfer_client"
                            />
                        }
                        label="Transfer Client"
                    />
                    <TextField
                        label="Notes Driver"
                        name="notes_driver"
                        value={formData.notes_driver || ''}
                        onChange={handleInputChange}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.is_submitted || false}
                                onChange={handleInputChange}
                                name="is_submitted"
                            />
                        }
                        label="Is Submitted"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EditionCard;
