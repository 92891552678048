import React, { useState } from "react";

function YourDelivery() {
    const [deliveryId, setDeliveryId] = useState('');

    const handleInputChange = (e) => {
        setDeliveryId(e.target.value); // Update deliveryId as the user types
    };

    const searchDelivery = () => {
        window.location.href = `https://www.fastmaxlima.pe/#/${deliveryId}`; // Navigate to the delivery tracking page
    };

    return (
        <div className="tracking-container" style={{ padding: '40px', textAlign: 'center' }}>
            <h2>Sigue tu entrega en tiempo real</h2>
            <input 
                type="number" 
                value={deliveryId} 
                onChange={handleInputChange} 
                placeholder="Ingresa el ID de tu entrega"
                style={{ margin: '10px', padding: '10px', width: '300px' }}
            />
            <button onClick={searchDelivery} style={{ padding: '10px 20px' }}>Buscar</button>
        </div>
    );
}

export default YourDelivery;
