export const districts = {
    "Ate": { latitude: -12.037, longitude: -76.955 },
    "Barranco": { latitude: -12.145, longitude: -77.021 },
    "Breña": { latitude: -12.063, longitude: -77.049 },
    "Carabayllo": { latitude: -11.884, longitude: -77.038 },
    "Chaclacayo": { latitude: -11.981, longitude: -76.793 },
    "Chorrillos": { latitude: -12.178, longitude: -77.001 },
    "Cieneguilla": { latitude: -12.088, longitude: -76.844 },
    "Comas": { latitude: -11.936, longitude: -77.060 },
    "El Agustino": { latitude: -12.047, longitude: -76.997 },
    "Independencia": { latitude: -11.982, longitude: -77.049 },
    "Jesús María": { latitude: -12.076, longitude: -77.049 },
    "La Molina": { latitude: -12.073, longitude: -76.947 },
    "La Victoria": { latitude: -12.071, longitude: -77.013 },
    "Lince": { latitude: -12.087, longitude: -77.035 },
    "Los Olivos": { latitude: -11.981, longitude: -77.067 },
    "Lurigancho": { latitude: -11.981, longitude: -76.855 },
    "Lurin": { latitude: -12.258, longitude: -76.896 },
    "Magdalena del Mar": { latitude: -12.090, longitude: -77.069 },
    "Miraflores": { latitude: -12.120, longitude: -77.030 },
    "Pachacamac": { latitude: -12.241, longitude: -76.838 },
    "Pueblo Libre": { latitude: -12.078, longitude: -77.073 },
    "Puente Piedra": { latitude: -11.872, longitude: -77.069 },
    "Rimac": { latitude: -12.026, longitude: -77.026 },
    "San Borja": { latitude: -12.101, longitude: -77.001 },
    "San Isidro": { latitude: -12.096, longitude: -77.036 },
    "San Juan de Lurigancho": { latitude: -12.006, longitude: -76.988 },
    "San Juan de Miraflores": { latitude: -12.152, longitude: -76.963 },
    "San Luis": { latitude: -12.073, longitude: -77.000 },
    "San Martin de Porres": { latitude: -12.015, longitude: -77.059 },
    "San Miguel": { latitude: -12.080, longitude: -77.096 },
    "Santa Anita": { latitude: -12.052, longitude: -76.971 },
    "Santiago de Surco": { latitude: -12.140, longitude: -76.995 },
    "Surquillo": { latitude: -12.116, longitude: -77.022 },
    "Villa El Salvador": { latitude: -12.193, longitude: -76.949 },
    "Villa Maria del Triunfo": { latitude: -12.161, longitude: -76.944 },
    "Cercado de Lima": { latitude: -12.046, longitude: -77.031 },
    "Callao": { latitude: -12.061, longitude: -77.151 },
    "Bellavista": { latitude: -12.072, longitude: -77.122 },
    "Carmen de La Legua Reynoso": { latitude: -12.048, longitude: -77.096 },
    "La Perla": { latitude: -12.076, longitude: -77.116 },
    "La Punta": { latitude: -12.075, longitude: -77.167 },
    "Ventanilla": { latitude: -11.878, longitude: -77.135 },
    "Santa Rosa": { latitude: -11.796, longitude: -77.159 },
    "Ancon": { latitude: -11.719, longitude: -77.177 },
  };