import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import { districts } from '../user/Districs';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuDriver from "./MenuDriver";
import MarkerTextDriver from './MarkerTextDriver';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function MapDriver({ setActiveComponent, handleEntregasClick, handleRecojosClick, userId, onBackClick, setMainDeliveryPoints, setMainOrder}) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [user, setUser] = useState(null);
  const [deliveryPoints, setDeliveryPoints] = useState([]);
  const [deliveryPointsMarkers, setDeliveryPointsMarkers] = useState([]);
  const [userMarker, setUserMarker] = useState(null);
  const [driver, setDriver] = useState(null);
  const [currentLocationMarker, setCurrentLocationMarker] = useState(null);
  const [orderPointsMarkers, setOrderPointsMarkers] = useState([]);
  const [driverMarker, setDriverMarker] = useState([]);
  const [showMarkers, setShowMarkers] = useState(false);
  const [orderPoints, setOrderPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [sequence, setSequence] = useState(0);
  const [updatedPoints, setUpdatedPoints] = useState([]);
  const [updatedOrders, setUpdatedOrders] = useState([]);
  const [showTextField, setShowTextField] = useState(false);
  const [selectedMapDeliveryPoint, setSelectedMapDeliveryPoint] = useState(null);
  const [showDeliveryPoint, setShowDeliveryPoint] = useState(false);
  const [selectedMapOrder, setSelectedMapOrder] = useState(null);
  const [showOrder, setShowOrder] = useState(false);
  


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/driver/${userId}`)
      .then(response => {
        setUser(response.data);
        console.log('user ', response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [userId]);

  useEffect(() => {
    if (user) {
      // Function to fetch delivery and order points
      const fetchData = () => {
        // Fetch delivery points
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/driver/${userId}`)
          .then(response => {
            setDeliveryPoints(response.data);
            console.log('delivery points ', response.data);
          })
          .catch(error => console.error('Error fetching delivery points:', error));
  
        // Fetch order points
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/driver/${userId}`)
          .then(response => {
            setOrderPoints(response.data);
            console.log('order points ', response.data);
          })
          .catch(error => console.error('Error fetching order points:', error));
      };
  
      // Call fetchData immediately on component mount
      fetchData();
  
      // Set up the interval to fetch data every 5 seconds (5000ms)
      const intervalId = setInterval(() => {
        fetchData();
      }, 5000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [user]);

  const initializeUserMarker = () => {
    if (userMarker) {
      userMarker.remove();
    }
  
    if (user && user.default_latitude && user.default_longitude) {
      // Create a DOM element containing the StoreIcon
      const storeIconElement = document.createElement('div');
      storeIconElement.innerHTML = ReactDOMServer.renderToString(<HomeIcon style={{ fontSize: '40px', color: 'black' }} />);
  
      // Create the marker using the store icon
      const marker = new mapboxgl.Marker({
        element: storeIconElement
      })
        .setLngLat([user.default_longitude, user.default_latitude])
        .setPopup(new mapboxgl.Popup().setHTML(`<h2 style="color: black;">${user.username}</h3>`))
        .addTo(mapRef.current);
  
      setUserMarker(marker);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      initializeDriverMarker();
    }
  }, [driver]);

  useEffect(() => {
    const fetchDriverLocation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
          params: {
            driver_id: userId, // Send the driver_id as a query parameter
          },
        });
        console.log('Driver location:', response.data);

        // Update the driver state with the new data
        setDriver(response.data);
      } catch (error) {
        console.error('Error fetching driver location:', error);
      }
    };
    fetchDriverLocation();
    // Fetch the driver location every 4 seconds
    // const intervalId = setInterval(fetchDriverLocation, 4000);

    // // Cleanup the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);


  const initializeDriverMarker = () => {
    // Clear existing marker if it exists
    if (driverMarker.current) {
      driverMarker.current.remove();
    }
  
    if (driver && driver.longitude && driver.latitude) {
      // Create a DOM element for the marker
      const logoElement = document.createElement('div');
      logoElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;
  
      // Create a new marker for the driver
      const marker = new mapboxgl.Marker({
        element: logoElement
      })
        .setLngLat([driver.longitude, driver.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(`
          <div>
            <h3 style="color: black; display: inline; font-size: 22px;">${driver.name}</h3>
            <span style="cursor: pointer; display: inline-block; margin-left: 10px;" onclick="window.open('https://wa.me/51${driver.email}', '_blank')">
            
            </span>
          </div>
        `))
        .addTo(mapRef.current);
  
      // Set the marker in the ref for future updates
      driverMarker.current = marker;
    }
  };

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-77.03711992646711, -12.060235303763926], // Default coordinates (Lima)
        zoom: 12
      });
  
      mapRef.current.on('load', () => {
        // initializeUserMarker();
        // // initializeDeliveryPointMarkers(); if necessary but doble markers are showing
        // fitMapToMarkers();
      });
    } else if (mapRef.current) {
      // initializeUserMarker();
      // initializeDeliveryPointsMarkers();
      // initializeOrderPointsMarkers();
      // fitMapToMarkers();
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      initializeUserMarker();
    }
  }, [user]);

  useEffect(() => {
    if (mapRef.current) {
      initializeDeliveryPointsMarkers();
      
    }
  }, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current) {
      initializeOrderPointsMarkers();
    }
  }, [orderPoints]);
  
  const fitMapToMarkers = () => {
    if (mapRef.current) {
      const bounds = new mapboxgl.LngLatBounds();
  
      // Add user location to bounds
      if (user && user.default_latitude && user.default_longitude) {
        bounds.extend([user.default_longitude, user.default_latitude]);
      }
  
      // Add delivery points to bounds
      deliveryPoints.forEach(point => {
        if (point.longitude && point.latitude) {
          bounds.extend([point.longitude, point.latitude]);
        }
      });
    
      // Add order points to bounds
      orderPoints.forEach(point => {
        if (point.lon && point.lat) {
          bounds.extend([point.lon, point.lat]);
        }
      });
  
      // Fit the map to the bounds, with some padding
      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds, { padding: 50 });
      }
    }
  };

  const initializeMarkers = (points, setMarkers, markerShape) => {
    // Remove existing markers
    Object.values(markerShape === 'circle' ? deliveryPointsMarkers : orderPointsMarkers).forEach(marker => marker.remove());
    const newMarkers = {};
  
    points.forEach(point => {
      let { latitude, longitude } = point;
      let color;
  
      // Determine the marker color based on the status
      switch (point.status) {
        case 'pending':
          color = 'orange';
          break;
        case 'recogido':
          color = 'purple'; 
          break;
        case 'entregado':
          color = 'green';
          break;
        case 'cancelado':
          color = 'red';
          break;
        case 'encamino':
          color = 'blue';
          break;
        default:
          color = 'gray';
      }
  
      // Check if latitude and longitude are missing
      if (!latitude || !longitude) {
        // Look for the district data
        const districtData = districts[point.distric];
        if (districtData) {
          // Set latitude and longitude based on the district data
          latitude = districtData.latitude + (Math.random() - 0.5) * 0.01; // Random offset
          longitude = districtData.longitude + (Math.random() - 0.5) * 0.01; // Random offset
        }
      }
  
      // Only create a marker if we have valid coordinates
      if (latitude && longitude) {
        // Create a custom HTML element for the marker with the sequence number
        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.backgroundColor = color;
        markerElement.style.width = '20px';
        markerElement.style.height = '20px';
        markerElement.style.display = 'flex';
        markerElement.style.alignItems = 'center';
        markerElement.style.justifyContent = 'center';
        markerElement.style.color = 'white';
        markerElement.style.fontSize = '10px';
        markerElement.textContent = point.sequence; // Add the sequence number inside the marker
  
        // Apply different shapes for orders and deliveries
        if (markerShape === 'circle') {
          markerElement.style.borderRadius = '50%';
        } else if (markerShape === 'square') {
          markerElement.style.borderRadius = '0%';
        }
  
        const marker = new mapboxgl.Marker({
          element: markerElement
        })
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current);
  
          marker.getElement().addEventListener('click', () => {
            if (point.status === 'encamino') {
              setSelectedPoint(point);
            } else if (point.status === 'pending' && !point.customer_id) {
              handleSequenceChange(point);  // Call sequence change if status is pending
            } else if (point.status === 'entregado') {
              setSelectedPoint(point);  
            } else if (point.status === 'pending' && point.customer_id) {
              handleOrderSequenceChange(point);  
            } 
          });
  
        newMarkers[point.id] = marker;
      }
    });
  
    setMarkers(newMarkers);
  };

  const handleSequenceChange = async (point) => {
    const newSequence = prompt("Ingrese el nuevo número de secuencia:", point.sequence || 0);
    if (newSequence && !isNaN(newSequence)) {
      console.log('id ', point.id);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/change/sequence`, {
          id: point.id,
          sequence: newSequence
        });
        
        
        if (response.status === 200) {
          setSequence(newSequence);  // Update local state
          alert('Secuencia actualizada exitosamente');
        } else {
          alert('Error al actualizar la secuencia');
        }
      } catch (error) {
        console.error('Error al actualizar la secuencia:', error);
        alert('Ocurrió un error al actualizar la secuencia');
      }
    } else {
      alert('Por favor, ingrese un número válido.');
    }
  };

  const handleOrderSequenceChange = async (point) => {
    const newSequence = prompt("Ingrese el nuevo númeroo de secuencia:", point.sequence || 0);
    if (newSequence && !isNaN(newSequence)) {
      console.log('id ', point.id);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/change/order_sequence`, {
          id: point.id,
          sequence: newSequence
        });
        
        
        if (response.status === 200) {
          setSequence(newSequence);  // Update local state
          alert('Secuencia actualizada exitosamente');
        } else {
          alert('Error al actualizar la secuencia');
        }
      } catch (error) {
        console.error('Error al actualizar la secuencia:', error);
        alert('Ocurrió un error al actualizar la secuencia');
      }
    } else {
      alert('Por favor, ingrese un número válido.');
    }
  };

  const handleStartRoute = () => {
    // Get all delivery point IDs with status 'pending'
    const pendingPointIds = deliveryPoints
        .filter(point => point.status === 'pending')
        .map(point => point.id);

    if (pendingPointIds.length === 0) {
        alert('Tu ruta ya empezo.');
        return;
    }

    // Call API to update statuses
    axios.post(`${process.env.REACT_APP_API_URL}/api/start_route`, {
        point_ids: pendingPointIds
    })
    .then(response => {
        alert('Empezaste tu ruta! suerte.');
        setUpdatedPoints(pendingPointIds);  // Optionally update state to reflect the changes
    })
    .catch(error => {
        console.error('Error updating delivery points:', error);
        alert('Failed to update delivery points.');
    });
};

const handleOrdersStartRoute = () => {
  // Get all delivery point IDs with status 'pending'
  const pendingOrderIds = orderPoints
      .filter(order => order.status === 'pending')
      .map(order => order.id);

  if (pendingOrderIds.length === 0) {
      alert('Tu ruta ya empezo.');
      return;
  }

  // Call API to update statuses
  axios.post(`${process.env.REACT_APP_API_URL}/api/start_orders_route`, {
      order_ids: pendingOrderIds
  })
  .then(response => {
      alert('Empezaste tu ruta, suerte!');
      setUpdatedOrders(pendingOrderIds);  // Optionally update state to reflect the changes
  })
  .catch(error => {
      console.error('Error updating orders:', error);
      alert('Failed to update orders.');
  });
};

  const initializeDeliveryPointsMarkers = () => {
    initializeMarkers(deliveryPoints, setDeliveryPointsMarkers, 'circle');
  };
  
  const initializeOrderPointsMarkers = () => {
    initializeMarkers(orderPoints, setOrderPointsMarkers, 'square');
  };

//   const updateCurrentLocationMarker = (position) => {
//     const { latitude, longitude } = position.coords;

//     if (currentLocationMarker) {
//       currentLocationMarker.setLngLat([longitude, latitude]); // Update existing marker position
//     } else {
//       // Create a DOM element for the logo
//       const logoElement = document.createElement('div');
//       logoElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;

//       // Create the marker using the logo element
//       const marker = new mapboxgl.Marker({
//         element: logoElement
//       })
//         .setLngLat([longitude, latitude])
//         .addTo(mapRef.current);

//       setCurrentLocationMarker(marker);
//     }

//     // mapRef.current.flyTo({
//     //   center: [longitude, latitude],
      
//     //   essential: true // This ensures the animation will be completed
//     // });
// };

  // Start watching the user's location and update the marker
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     const watchId = navigator.geolocation.watchPosition(
  //       updateCurrentLocationMarker,
  //       (error) => console.error("Error watching position: ", error),
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0
  //       }
  //     );

  //     return () => navigator.geolocation.clearWatch(watchId); // Clear the watch when the component unmounts
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, [currentLocationMarker]);


  const handleLogoClick = () => {
    setActiveComponent('Options')
  }

  const handleResumenClick = () => {
    setActiveComponent('ResumenDriver');
  };

  const handleClosePopup = () => {
    setSelectedPoint(null);
  };
  
  return (
    <div className="map">
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "70vh" }}
      ></div>
      <div
        style={{
            position: "absolute",
            top: "25px",
            left: "25px",
            zIndex: 2000, // Ensure it stays on top
        }}
        onClick={handleLogoClick}
        >
        <img src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`} alt="Logo" style={{ height: "78px", width: "78px" }} />
        </div>

        <div
        style={{
          
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
      }}
        >
          </div>
          <div
        style={{
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
        }}
      >
        {selectedPoint && (
          <MarkerTextDriver point={selectedPoint} onClose={handleClosePopup} />
        )}
      </div>
      <IconButton
        style={{
          position: "absolute",
          bottom: "72%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.9)",
        }}
        onClick={handleOrdersStartRoute}
      >
        <PlayCircleIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "66%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.9)",
        }}
        onClick={handleStartRoute}
      >
        <PlayArrowIcon />
      </IconButton>
          <IconButton
        style={{
          position: "absolute",
          bottom: "60%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.9)",
        }}
        onClick={handleResumenClick}
      >
        <MonetizationOnIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "54%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.9)",
        }}
        onClick={handleRecojosClick}
      >
        <SupportAgentIcon />
      </IconButton>
          <IconButton
        style={{
          position: "absolute",
          bottom: "44%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "80%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.7)",
        }}
        onClick={handleEntregasClick}
      >
        <AddIcon />
      </IconButton>
      <div
              style={{
                height: "40vh",
                padding: "20px",
                backgroundColor: "#f7f7f7",
                overflowY: "auto",
              }}
            >
              {user && (
                    <MenuDriver user={user} userId={userId} deliveryPoints={deliveryPoints} setActiveComponent={setActiveComponent} orderPoints={orderPoints} onBackClick={onBackClick} setMainDeliveryPoints={setMainDeliveryPoints} setMainOrder={setMainOrder} />
                  )}

              
            </div>
        </div>
    
  );
}

export default MapDriver;
