import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import PersonIcon from '@mui/icons-material/Person';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/material';
import ReactMapGL, { Marker } from 'react-map-gl';
import EditIcon from '@mui/icons-material/Edit';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import EditionCard from './EditionCard';
import FindUserMap from './FindUserMap';
import AddImageCard from './AddImageCard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import axios from 'axios';  // Import axios

const SearchResultCard = ({ onSearch, deliveryPoint, onClose }) => {
    // Move the destructured data into state so it can be updated dynamically
    const [deliveryData, setDeliveryData] = useState(deliveryPoint);

    const { id, user_id, driver_id, recipient_phone, charge, charged, notes_driver, transfer, transfer_client, status, recipient_name, address, image_urls, notes, latitude, longitude, sequence, district, arrival_time } = deliveryData;

    const [viewport, setViewport] = useState({
        latitude: latitude || -12.0464,
        longitude: longitude || -77.0428,
        zoom: 14,
        width: '100%',
        height: '100%'
    });

    // Update viewport whenever latitude and longitude change
    useEffect(() => {
        setViewport((prevViewport) => ({
            ...prevViewport,
            latitude: latitude || -12.0464,
            longitude: longitude || -77.0428,
        }));
    }, [latitude, longitude]);

    const [showEditionCard, setShowEditionCard] = useState(false);
    const [showMapUserCard, setShowMapUserCard] = useState(false);
    const [showAddImageCard, setShowAddImageCard] = useState(false);

    const defaultImages = [
        `${process.env.PUBLIC_URL}/churro.webp`,
    ];

    const images = image_urls && image_urls.length > 0 ? image_urls : defaultImages;

    // Fetch updated delivery point data every 3 seconds
    useEffect(() => {
        const fetchUpdatedDeliveryPoint = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/delivery_point`, {
                    params: { entrega_id: id }
                });

                if (response.status === 200) {
                    setDeliveryData(response.data); // Update the delivery data state
                }
            } catch (error) {
                console.error('Error fetching updated delivery point:', error);
            }
        };

        // Set interval to fetch data every 3 seconds
        const intervalId = setInterval(fetchUpdatedDeliveryPoint, 3000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [id]);  // Run only when the `id` changes

    const handleUserClick = async () => {
        let response;

        response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
            params: { id: user_id },
        });
        onSearch(response.data, 'user');
    };

    const handleDriverClick = async () => {
        let response;

        response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
            params: { id: driver_id },
        });
        onSearch(response.data, 'driver');
    };

    const handleEditClick = () => {
        setShowEditionCard(true);
    };

    const handleCloseEditionCard = () => {
        setShowEditionCard(false);
    };

    const handleFindUserMapClick = () => {
        setShowMapUserCard(true);
    };

    const handleCloseFindUserMapCard = () => {
        setShowMapUserCard(false);
    };

    const handleCloseAddImageCard = () => {
        setShowAddImageCard(false);
    };

    const handleAddImageClick = () => {
        setShowAddImageCard(true);
    };

    const handleChangeDriverClick = (deliveryPointId) => {
        // Prompt the user for the new driver ID
        const newDriverId = prompt("Por favor, ingresa el nuevo ID del conductor:", "");
    
        // If the user cancels or enters nothing, exit the function
        if (newDriverId === null || newDriverId.trim() === "") {
            alert("Operación cancelada o ID no válido.");
            return;
        }
    
        // Proceed with the API call if a valid ID was entered
        axios.post(`${process.env.REACT_APP_API_URL}/api/update/driver_id`, {
            id: deliveryPointId,  // The delivery point ID
            driver_id: newDriverId.trim()  // The new driver ID, trimmed for extra spaces
        })
        .then(response => {
            alert("El conductor ha sido actualizado exitosamente.");
            // Optionally, update the UI or trigger a state refresh here
        })
        .catch(error => {
            console.error('Error updating driver:', error);
            alert('No se pudo actualizar el conductor.');
        });
    };

    const handleSequenceChange = async () => {
        const newSequence = prompt("Ingrese el nuevo número de secuencia:", deliveryData.sequence || 0);
        if (newSequence && !isNaN(newSequence)) {
          console.log('id ', deliveryData.id);
          try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/change/sequence`, {
              id: deliveryData.id,
              sequence: newSequence
            });
            
            
            if (response.status === 200) {
              alert('Secuencia actualizada exitosamente');
            } else {
              alert('Error al actualizar la secuencia');
            }
          } catch (error) {
            console.error('Error al actualizar la secuencia:', error);
            alert('Ocurrió un error al actualizar la secuencia');
          }
        } else {
          alert('Por favor, ingrese un número válido.');
        }
      };

      

    const handleDeleteClick = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this delivery point?");
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_delivery_point/${id}`);
            if (response.status === 200) {
                alert('DeliveryPoint deleted successfully');
                onClose();  // Close the card after deletion
            } else {
                alert('Failed to delete DeliveryPoint');
            }
        } catch (error) {
            console.error('Error deleting delivery point:', error);
            alert('Error deleting delivery point');
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${deliveryData.recipient_phone}`, '_blank');
    };

    const handleMessageClick = async (id, type) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_treinta_minutos`, {
                id: id,
                type: type  // either 'order' or 'delivery'
            });

            if (response.status === 200) {
                console.log(`${type} treinta_minutos updated successfully`);
                window.alert(`El chamo llega en 30 minutos`);
            } else {
                console.error(`Failed to update ${type} tres_minutos`);
            }
        } catch (error) {
            console.error(`Error updating ${type} tres_minutos:`, error);
        }
    };

    const handleLocationClick = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_ubicacion_enviado_false`, {
                id: id,
            });

            if (response.status === 200) {
                console.log(`falta_ubicacion_message_false updated successfully`);
                window.alert(`Falta Ubicaion enviado false`);
            } else {
                console.error(`Failed to update  Location_message`);
            }
        } catch (error) {
            console.error(`Error updating  Location_message:`, error);
        }
    };

    const formatArrivalTime = (time) => {
        const date = new Date(time);
        const hour = date.getHours() - 5;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        // Calculate the time range (±1 hour)
        const startHour = (hour - 0 + 24) % 24; // To handle negative hours
        
        
        return `${startHour}:${minutes}`;
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 600, position: 'relative', top: "80px", left: "10px", zIndex: '2000' }}>
                <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {recipient_name.length > 25 ? `${recipient_name.substring(0, 25)}...` : recipient_name} {id} {status}
                </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                        {address.length > 25 ? `${address.substring(0, 25)}...` : address}, 
                        {charge}
                    </Typography>
                        <Box>
                            <IconButton size="small" onClick={() => handleLocationClick(deliveryData.id)}>
                                <LocationOffIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleMessageClick(deliveryData.id, 'delivery')}>
                                <AccessAlarmIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleSequenceChange}>
                                <FormatListNumberedRtlIcon />
                            </IconButton>

                            <IconButton size="small" onClick={handleUserClick}>
                                <PersonIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDriverClick}>
                                <SportsMotorsportsIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleFindUserMapClick}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                        {notes.length > 25 ? `${notes.substring(0, 25)}...` : notes} {transfer ? 'Transferencia: Si' : transfer_client ? 'Transferencia a Cliente: Si' : 'No Transfer Info'}, {charged}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={() => handleChangeDriverClick(id)}>
                                <AccessibleForwardIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleAddImageClick}>
                                <AddAPhotoIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleWhatsAppClick}>
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                            {district} {recipient_phone} {sequence}  {driver_id} {notes_driver} {formatArrivalTime(arrival_time)}
                        </Typography>
                    
                    </Box>
                </CardContent>

                <Box sx={{ display: 'flex', flexDirection: 'row', height: '320px', marginTop: 2 }}>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Carousel>
                            {images.map((image, index) => (
                                <CardMedia
                                    key={index}
                                    component="img"
                                    alt={`${recipient_name} image ${index + 1}`}
                                    height="320"
                                    image={image}
                                />
                            ))}
                        </Carousel>
                    </Box>
                    <Box sx={{ width: '65%', height: '100%' }}>
                        <ReactMapGL
                            {...viewport}
                            mapboxApiAccessToken="YOUR_MAPBOX_ACCESS_TOKEN"
                            onViewportChange={(nextViewport) => setViewport(nextViewport)}
                            mapStyle="mapbox://styles/mapbox/streets-v11"
                        >
                            <Marker latitude={latitude} longitude={longitude}>
                                <img src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`} alt="Location Marker" style={{ height: 30, width: 30 }} />
                            </Marker>
                        </ReactMapGL>
                    </Box>
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>

            {showEditionCard && (
                <EditionCard deliveryPoint={deliveryData} onClose={handleCloseEditionCard} />
            )}
            {showMapUserCard && (
                <FindUserMap onClose={handleCloseFindUserMapCard} deliveryId={id} />
            )}
            {showAddImageCard && (
                <AddImageCard deliveryId={id} onClose={handleCloseAddImageCard} />
            )}
        </Box>
    );
};

export default SearchResultCard;
