import React, { useState } from 'react';
import axios from 'axios';
import { Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GlobalSearch = ({ onBackClick }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ delivery_points: [], orders: [], users: [] });
  const [page, setPage] = useState(1);

  const handleSearch = async (page = 1) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search_global`, { params: { query, page, per_page: 5 } });
      setResults(response.data);
      setPage(page);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const loadMoreDeliveryPoints = () => {
    handleSearch(page + 1);
  };

  const loadMoreOrders = () => {
    handleSearch(page + 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: 'scroll', padding: '10px' }}>
      <Box sx={{ marginBottom: 2 }}>
        <ArrowBackIcon onClick={onBackClick} style={{ color: 'black', cursor: 'pointer' }} />
      </Box>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <input
          type="text"
          placeholder="Search by name, address, phone number..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{ flex: 1, padding: '8px', marginRight: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
        />
        <button 
          onClick={() => handleSearch()}
          style={{ padding: '8px 16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
        >
          Search
        </button>
      </div>

      <div style={{ flex: 1, overflowY: 'auto' }}>
        <h3>Delivery Points</h3>
        {results.delivery_points.map((dp) => (
          <div key={dp.id} style={{ marginBottom: '5px', padding: '5px', borderBottom: '1px solid #e0e0e0' }}>
            <p style={{ margin: '2px 0' }}>ID: {dp.id} ESTADO: {dp.status}</p>
            <p style={{ margin: '2px 0' }}>Nombre: {dp.recipient_name}</p>
            <p style={{ margin: '2px 0' }}>Cliente: {dp.user_name}</p>
            <p style={{ margin: '2px 0' }}>Direccion: {dp.address}</p>
            <p style={{ margin: '2px 0' }}>Telefono: {dp.recipient_phone}</p>
          </div>
        ))}
        {results.delivery_points.length > 0 && results.pagination.page * results.pagination.per_page < results.pagination.total_delivery_points && (
          <button 
            onClick={loadMoreDeliveryPoints}
            style={{ padding: '8px 16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' }}
          >
            Load More Delivery Points
          </button>
        )}
      </div>

      <div style={{ flex: 1, overflowY: 'auto' }}>
        <h3>Orders</h3>
        {results.orders.map((order) => (
          <div key={order.id} style={{ marginBottom: '5px', padding: '5px', borderBottom: '1px solid #e0e0e0' }}>
            <p style={{ margin: '2px 0' }}>ID: {order.id}</p>
            <p style={{ margin: '2px 0' }}>Customer Name: {order.customer_name}</p>
            <p style={{ margin: '2px 0' }}>Status: {order.status}</p>
            <p style={{ margin: '2px 0' }}>Address: {order.address?.address}</p>
          </div>
        ))}
        {results.orders.length > 0 && results.pagination.page * results.pagination.per_page < results.pagination.total_orders && (
          <button 
            onClick={loadMoreOrders}
            style={{ padding: '8px 16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' }}
          >
            Load More Orders
          </button>
        )}
      </div>

      <div style={{ flex: 1, overflowY: 'auto' }}>
        <h3>Users</h3>
        {results.users.map((user) => (
          <div key={user.id} style={{ marginBottom: '5px', padding: '5px', borderBottom: '1px solid #e0e0e0' }}>
            <p style={{ margin: '2px 0' }}>ID: {user.id}</p>
            <p style={{ margin: '2px 0' }}>Email: {user.email}</p>
            <p style={{ margin: '2px 0' }}>Username: {user.username}</p>
            <p style={{ margin: '2px 0' }}>Phone: {user.phone_number}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalSearch;