import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Box, List, ListItem, ListItemText, Button, TextField } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';

const SearchDriverResultCard = ({ user, onClose }) => {
    const { id, phone_number, email, username } = user;
    const [entregas, setEntregas] = useState([]);
    const [recojos, setRecojos] = useState([]);
    const [driver, setDriver] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [total_deposit, setTotalDeposit] = useState(0);
    const [images, setImages] = useState([]);
    console.log('selectedDelivery:', selectedDelivery);

    useEffect(() => {
        if (selectedDate) {
            fetchDeliveries();
        }
    }, [selectedDate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
                    params: {
                      driver_id: id, // Send the driver_id as a query parameter
                    },
                });
                setDriver(response.data);  // Correctly set the fetched driver data
                console.log('Fetched driver:', response.data);
                console.log('Driver:', driver);  // This will still be the previous state because state updates are asynchronous
            } catch (error) {
                console.error('Error fetching driver:', error);
            }
        };
    
        if (id) {
            fetchDriver();  // Call the async function to fetch data
        }
    }, [id]);

    const handlePickupRoutes = async () => {
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        const start = [driver.final_latitude, driver.final_longitude]; // Driver's default location
        const end = [-12.00093305507352, -77.05374889286432]; // Specific end location
    
        const matchingPoints = recojos.filter(point => point.driver_id === id);
        console.log('Matching Points:', matchingPoints);
        const stops = matchingPoints.map(point => [point.address.latitude, point.address.longitude]);
    
        console.log('Driver home address', driver.final_latitude, driver.final_longitude);
        console.log('Stops', stops);
    
        // Optimize stops order using Nearest Neighbor Algorithm
        const optimizedStops = nearestNeighbor(start, [...stops]);
        console.log('Optimized Stops:', optimizedStops);
    
        const route = await createRoute(start, end, optimizedStops);
    
        // Extract arrival times from each leg
        const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
        // Map pickup points to optimized stops order and include arrival times
        const newSequence = optimizedStops.map((stop, index) => {
            const point = matchingPoints.find(mp => mp.address.latitude === stop[0] && mp.address.longitude === stop[1]);
            const arrivalTime = arrivalTimes[index];
            return point ? { id: point.id, arrival_time: arrivalTime } : null;
        }).filter(entry => entry !== null);
    
        console.log('New sequence:', newSequence);
    
        // Call the backend endpoint to update the sequence and arrival times in the database
        try {
            const token = localStorage.getItem("token");
            await axios.post(`${process.env.REACT_APP_API_URL}/api/update_pickup_sequences`, { sequences: newSequence }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Sequence and arrival times updated successfully');
        } catch (error) {
            console.error('Error updating sequence and arrival times:', error);
        }
    };

    const handlePickupRoutesSequence = async () => {
        if (recojos.length === 0) return;

        const sortedOrderPoints = recojos
            .filter(order => order.status === 'encamino')
            .sort((a, b) => a.sequence - b.sequence);

        if (sortedOrderPoints.length === 0) {
            console.log('No pending orders to process.');
            return;
        }

        
            
        const startPoint = [driver.latitude, driver.longitude]; // Driver's default location
        const endPoint = [-12.00093305507352, -77.05374889286432];
        const stops = sortedOrderPoints.map(order => [order.address.latitude, order.address.longitude]);

                try {
                    const route = await createRoute(startPoint, endPoint, stops);
                    const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);

                    const updatedOrders = sortedOrderPoints.map((order, index) => ({
                        id: order.id,
                        arrival_time: arrivalTimes[index],
                        timezone: 'America/Lima'
                    }));

                    const token = localStorage.getItem("token");
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/update_arrival_times`, { orders: updatedOrders }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    console.log('Arrival times updated successfully');
                } catch (error) {
                    console.error('Error updating arrival times:', error);
                }
        
    };

    const handleEntregaRoutes = async () => {
        const start = [driver.latitude, driver.longitude];
        const end = [driver.final_latitude, driver.final_longitude];
    
        const matchingPoints = entregas.filter(point => point.driver_id === id);
        const stops = matchingPoints.map(point => [point.latitude, point.longitude]);
    
        console.log('Driver home address', driver.final_latitude, driver.final_longitude);
        console.log('Stops', stops);
    
        // Optimize stops order using Nearest Neighbor Algorithm
        const optimizedStops = nearestNeighbor(start, [...stops]);
        console.log('Optimized Stops:', optimizedStops);
    
        const route = await createRoute(start, end, optimizedStops);
    
        // Extract arrival times from each leg
        const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
        // Map delivery points to optimized stops order and include arrival times
        const newSequence = optimizedStops.map((stop, index) => {
            const point = matchingPoints.find(mp => mp.latitude === stop[0] && mp.longitude === stop[1]);
            const arrivalTime = arrivalTimes[index];
            return point ? { id: point.id, arrival_time: arrivalTime } : null;
        }).filter(entry => entry !== null);
    
        console.log('New sequence:', newSequence);
    
        // Call the backend endpoint to update the sequence and arrival times in the database
        try {
            const token = localStorage.getItem("token");
            await axios.post(`${process.env.REACT_APP_API_URL}/api/update_sequences`, { sequences: newSequence }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Sequence and arrival times updated successfully');
        } catch (error) {
            console.error('Error updating sequence and arrival times:', error);
        }
    };

    const handleEntregaRoutesSequence = async () => {
        if (entregas.length === 0) {
            console.log('No deliveries available.');
            return;
        } else if (entregas.length <= 2) {
            console.log('Not enough deliveries to create a route.');
            return;
        }
    
        
            
        const startPoint = [driver.latitude, driver.longitude];
                
                // Filter out deliveries that don't have valid latitude and longitude values
        const sortedDeliveries = entregas
                    .filter(delivery => delivery.status === 'encamino' && delivery.latitude !== undefined && delivery.longitude !== undefined)
                    .sort((a, b) => a.sequence - b.sequence);
    
                if (sortedDeliveries.length === 0) {
                    console.log('No valid deliveries with latitude and longitude.');
                    return;
                }
    
        const endPoint = [driver.final_latitude, driver.final_longitude];
        const stops = sortedDeliveries.map(delivery => [delivery.latitude, delivery.longitude]);
    
                try {
                    const route = await createRoute(startPoint, endPoint, stops);
                    const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
                    const updatedDeliveries = sortedDeliveries.map((delivery, index) => ({
                        id: delivery.id,
                        arrival_time: arrivalTimes[index],
                        timezone: 'America/Lima'
                    }));
    
                    const token = localStorage.getItem("token");
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/update_arrival_times_deliveries`, { deliveries: updatedDeliveries }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
    
                    console.log('Arrival times updated successfully');
                } catch (error) {
                    console.error('Error updating arrival times:', error);
                }
            
            
        
    };

    const createRoute = async (startPoint, endPoint, stops) => {
        const coordinates = [startPoint, ...stops, endPoint].map(coord => coord.join(',')).join(':');
        console.log('Coordinates:', coordinates);
        const subscriptionKey = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY; // Replace with your actual Azure Maps subscription key
    
        const url = 'https://atlas.microsoft.com/route/directions/json';
        const params = {
            'api-version': '1.0',
            'query': coordinates,
            'subscription-key': subscriptionKey,
            'routeType': 'fastest',
            'optimize': 'timeWithTraffic'
        };
    
        // Log the URL and parameters to debug
        console.log('Request URL:', url);
        console.log('Request Parameters:', params);
    
        try {
            const response = await axios.get(url, { params });
            console.log('Response:', response.data);
            return response.data.routes[0];
        } catch (error) {
            if (error.response) {
                console.error('Error creating route:', error.response.data);
            } else {
                console.error('Error creating route:', error.message);
            }
            throw error;
        }
    };

    const haversineDistance = (coord1, coord2) => {
        const toRad = (angle) => angle * (Math.PI / 180);
        
        const lat1 = coord1[0];
        const lon1 = coord1[1];
        const lat2 = coord2[0];
        const lon2 = coord2[1];
        
        const R = 6371; // Radius of the Earth in km
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + 
                  Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    };
    
    // Nearest Neighbor Algorithm
    const nearestNeighbor = (start, points) => {
        const visited = [];
        let currentPoint = start;
        
        while (points.length > 0) {
            let nearestPointIndex = -1;
            let nearestDistance = Infinity;
            
            points.forEach((point, index) => {
                const distance = haversineDistance(currentPoint, point);
                if (distance < nearestDistance) {
                    nearestDistance = distance;
                    nearestPointIndex = index;
                }
            });
    
            visited.push(points[nearestPointIndex]);
            currentPoint = points[nearestPointIndex];
            points.splice(nearestPointIndex, 1); // Remove the visited point from the list
        }
    
        return visited;
    };

    const handleEncaminoClick = async () => {
        // Find all delivery points with status 'pending'
        const pendingEntregas = entregas.filter(entrega => entrega.status === 'pending');
        
        // Extract their IDs
        const pendingIds = pendingEntregas.map(entrega => entrega.id);
    
        if (pendingIds.length === 0) {
            console.log('No pending deliveries to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_delivery_status`, {
                ids: pendingIds,  // Send the array of IDs to the backend
                status: 'encamino'
            });
            
            console.log('Updated delivery points:', response.data);
            // Optionally update local state after successful response
            setEntregas(entregas.map(entrega => 
                pendingIds.includes(entrega.id) ? { ...entrega, status: 'encamino' } : entrega
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };

    const handleOrderEncaminoClick = async () => {
        // Find all delivery points with status 'pending'
        const pendingRecojos = recojos.filter(recojo => recojo.status === 'pending');
        
        // Extract their IDs
        const pendingIds = pendingRecojos.map(recojo => recojo.id);
    
        if (pendingIds.length === 0) {
            console.log('No pending orders to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_order_status`, {
                ids: pendingIds,  // Send the array of IDs to the backend
                status: 'encamino'
            });
            
            console.log('Updated order points:', response.data);
            // Optionally update local state after successful response
            setRecojos(recojos.map(recojo => 
                pendingIds.includes(recojo.id) ? { ...recojo, status: 'encamino' } : recojo
            ));
        } catch (error) {
            console.error('Error updating recojo points:', error);
        }
    };

    const handlePendingClick = async () => {
        // Find all delivery points with status 'pending'
        const encaminoEntregas = entregas.filter(entrega => entrega.status === 'encamino');
        
        // Extract their IDs
        const encaminoIds = encaminoEntregas.map(entrega => entrega.id);
    
        if (encaminoIds.length === 0) {
            console.log('No encamino deliveries to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_delivery_status`, {
                ids: encaminoIds,  // Send the array of IDs to the backend
                status: 'pending'
            });
            
            console.log('Updated delivery points:', response.data);
            // Optionally update local state after successful response
            setEntregas(entregas.map(entrega => 
                encaminoIds.includes(entrega.id) ? { ...entrega, status: 'pending' } : entrega
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };

    const handleOrderPendingClick = async () => {
        // Find all delivery points with status 'pending'
        const encaminoRecojos = recojos.filter(recojo => recojo.status === 'encamino');
        
        // Extract their IDs
        const encaminoIds = encaminoRecojos.map(recojo => recojo.id);
    
        if (encaminoIds.length === 0) {
            console.log('No encamino orders to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_order_status`, {
                ids: encaminoIds,  // Send the array of IDs to the backend
                status: 'pending'
            });
            
            console.log('Updated order points:', response.data);
            // Optionally update local state after successful response
            setRecojos(recojos.map(recojo => 
                encaminoIds.includes(recojo.id) ? { ...recojo, status: 'pending' } : recojo
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };


    useEffect(() => {
        const calculateTotalDeposit = () => {
            const total = entregas.reduce((sum, entrega) => {
                // Add only if transfer is false and charged is a valid number
                return entrega.transfer === false ? sum + (entrega.charged || 0) : sum;
            }, 0);
            setTotalDeposit(total);  // Update the total deposit state
        };
    
        calculateTotalDeposit();  // Call the function to calculate the total
    }, [entregas]);

    const fetchDeliveries = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver_deliveries`, {
                params: {
                    driver_id: id,
                    date: selectedDate
                },
            });

            setEntregas(response.data.entregas);
            console.log('Entregas:', response.data.entregas);
            setRecojos(response.data.recojos);
            console.log('Recojos:', response.data.recojos);
        } catch (error) {
            console.error('Error fetching deliveries:', error);
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${phone_number}`, '_blank');
    };

    const handleDeliveryClick = (delivery) => {
        setSelectedDelivery(delivery);
        setImages(delivery.image_urls || []);  // Assuming delivery.image_urls is an array of image URLs
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 1000, height: 650,  position: 'relative', top: "80px", left: "10px", zIndex: '2000' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {username} {id} {phone_number}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                             {email}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={handleWhatsAppClick}>
                                <WhatsAppIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePickupRoutes}>
                                <AddBusinessIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePickupRoutesSequence}>
                                <HourglassTopIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOrderPendingClick}>
                                <PauseIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOrderEncaminoClick}>
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                              {total_deposit}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={handleEntregaRoutes}>
                                <LocalGroceryStoreIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEntregaRoutesSequence}>
                                <HourglassTopIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePendingClick}>
                                <PauseIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEncaminoClick}>
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    
                    <Box sx={{ marginTop: 2 }}>
                        <TextField
                            label="Pick Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </CardContent>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '420px', marginTop: 2 }}>
                    <Box sx={{ width: '30%', maxHeight: '400px', overflowY: 'auto' }}>
                        <Typography variant="h6">Lista de Recojos</Typography>
                        <List>
                            {recojos.map((recojo) => (
                                <ListItem button key={recojo.id} onClick={() => handleDeliveryClick(recojo)}>
                                    <ListItemText primary={`ID: ${recojo.id} - ${recojo.status} - ${recojo.sequence}`} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="h6">Lista de Entregas</Typography>
                        <List>
                            {entregas.map((entrega) => (
                                <ListItem button key={entrega.id} onClick={() => handleDeliveryClick(entrega)}>
                                    <ListItemText primary={`ID: ${entrega.id} - ${entrega.status} - ${entrega.sequence}`} />
                                    
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Detalles</Typography>
                        {selectedDelivery ? (
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    ID: {selectedDelivery.id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Estado: {selectedDelivery.status}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    User ID: {selectedDelivery.user_id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Nombre: {selectedDelivery.recipient_name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cobrar: {selectedDelivery.charge}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Transferencia FM: {selectedDelivery.transfer.toString()}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary">
                                    Cobrado: {selectedDelivery.charged}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas: {selectedDelivery.notes}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas Driver: {selectedDelivery.notes_driver}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Distrito: {selectedDelivery.district}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No delivery selected.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Imagenes</Typography>
                        {images.length > 0 ? (
                            <Carousel>
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Delivery Image ${index + 1}`}
                                        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
                                    />
                                ))}
                            </Carousel>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No images disponibles.
                            </Typography>
                        )}
                    </Box>
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>
        </Box>
    );
};

export default SearchDriverResultCard;
