import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, Typography, Button, List, ListItem, ListItemText, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';

const Mensajeria = ({ onBackClick }) => {
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [orderPoints, setOrderPoints] = useState([]);
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [messageType, setMessageType] = useState(null);

    useEffect(() => {

        const fetchDeliveryPointsAndOrders = async () => {
            try {
                // Fetch delivery points
                const deliveryPointsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/admin`);
                setDeliveryPoints(deliveryPointsResponse.data);
                console.log('Delivery Points:', deliveryPointsResponse.data);
                // Fetch order points
                const orderPointsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/admin`);
                setOrderPoints(orderPointsResponse.data);
                console.log('Order Points:', orderPointsResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // Call the function immediately and then every 4 seconds
        fetchDeliveryPointsAndOrders();
        const intervalId = setInterval(fetchDeliveryPointsAndOrders, 4000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleLocationClick = () => {
        const noLocationPoints = deliveryPoints.filter(point => 
            (!point.latitude || !point.longitude) && !point.ubicacion_enviado
        );
        setFilteredPoints(noLocationPoints);
        setMessageType('missingLocation');
        setCurrentIndex(0);
    };

    const update_ubicacion_enviado = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_ubicacion_enviado`, {
                id: id
            });
      
            if (response.status === 200) {
                console.log(`ubicacion_enviado updated succesfully`);

              } else {
                console.error(`Failed to update ubicacion_enviado`);
            }
        } catch (error) {
            console.error(`Error updating ubicacion_enviado:`, error);
        }
      };
      const update_terminado_enviado = async (id) => {
        try {
        
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_terminado_enviado`, {
                id: id
            });
      
            if (response.status === 200) {
                console.log(`terminado_enviado updated succesfully`);

              } else {
                console.error(`Failed to update terminado_enviado`);
            }
        } catch (error) {
            console.error(`Error updating terminado_enviado:`, error);
        }
      };

      const update_cobrar_enviado = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_cobrar_enviado`, {
                id: id
            });
      
            if (response.status === 200) {
                console.log(`cobrar_enviado updated succesfully`);

              } else {
                console.error(`Failed to update cobrar_enviado`);
            }
        } catch (error) {
            console.error(`Error updating cobrar_enviado:`, error);
        }
      };

      const update_encamino_enviado = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_encamino_enviado`, {
                id: id
            });
      
            if (response.status === 200) {
                console.log(`encamino_enviado updated succesfully`);

              } else {
                console.error(`Failed to update encamino_enviado`);
            }
        } catch (error) {
            console.error(`Error updating encamino_enviado:`, error);
        }
      };

      const update_encamino_recojo_enviado = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_encamino_recojo_enviado`, {
                id: id
            });
      
            if (response.status === 200) {
                console.log(`encamino_recojo_enviado updated succesfully`);

              } else {
                console.error(`Failed to update encamino_enviado`);
            }
        } catch (error) {
            console.error(`Error updating encamino_recojo_enviado:`, error);
        }
      };

    const handleLocationRepeatClick = () => {
        const noLocationPoints = deliveryPoints.filter(point => !point.latitude || !point.longitude);
        setFilteredPoints(noLocationPoints);
        setMessageType('missingLocationRepeat');
        setCurrentIndex(0);
    };

    const handleNoLocationClientClick = () => {
        const noLocationPoints = deliveryPoints.filter(point => !point.latitude || !point.longitude);
        setFilteredPoints(noLocationPoints);
        setMessageType('missingLocationClient');
        setCurrentIndex(0);
    };

    const handleOrderArrivalTime = () => {
        // Filter orders with status "encamino"
        const orderArrivalPoints = orderPoints.filter(order => order.status === 'encamino' && !order.encamino_enviado);
    
        // Update the state with the filtered points
        setFilteredPoints(orderArrivalPoints);
        setMessageType('orderArrival');
        setCurrentIndex(0); // Start at the first point in the filtered list
    };

    const handleDeliveryArrivalTime = () => {
        // Filter delivery points with status "encamino"
        const deliveryArrivalPoints = deliveryPoints.filter(delivery => delivery.status === 'encamino' && !delivery.encamino_enviado);
    
        // Update the state with the filtered points
        setFilteredPoints(deliveryArrivalPoints);
        setMessageType('deliveryArrival');
        setCurrentIndex(0); // Start at the first point in the filtered list
    };

    const handleOrderArrivalTimeClick = () => {
        const orderArrivalPoints = orderPoints.filter(order => 
            (order.treinta_minutos && !order.treinta_minutos_enviado)
        );
        setFilteredPoints(orderArrivalPoints);
        setMessageType('orderArrivalTime');
        setCurrentIndex(0);
    };

    const handleDeliveryArrivalTimeClick = () => {
        const deliveryArrivalPoints = deliveryPoints.filter(delivery => 
            (delivery.treinta_minutos && !delivery.treinta_minutos_enviado)
        );
        setFilteredPoints(deliveryArrivalPoints);
        setMessageType('deliveryArrivalTime');
        setCurrentIndex(0);
    };

    const handleDeliveryTresMinutesClick = () => {
        const deliveryTresMinutesPoints = deliveryPoints.filter(point => point.tres_minutos && !point.tres_minutos_enviado);
        setFilteredPoints(deliveryTresMinutesPoints);
        setMessageType('deliveryArrivalTime');
        setCurrentIndex(0);
    };


    const handleChargeClick = () => {
        const chargePoints = deliveryPoints.filter(point => point.charge && !point.cobrar_enviado);
        setFilteredPoints(chargePoints);
        setMessageType('charge');
        setCurrentIndex(0);
    };

    const handleFinishedClick = () => {
        const finishedOrders = orderPoints.filter(order => {
            const relatedDeliveryPoints = deliveryPoints.filter(dp => dp.user_id === order.customer_id);
            // Check if all related delivery points have the desired status and the order has terminado_enviado as false
            const allDeliveryPointsFinished = relatedDeliveryPoints.every(dp => 
                dp.status === 'entregado' || dp.status === 'pospuesto' || dp.status === 'cancelado'
            );
    
            return allDeliveryPointsFinished && !order.terminado_enviado;
        });
    
        setFilteredPoints(finishedOrders);
        setMessageType('finished');
        setCurrentIndex(0);
    };
    


    const handleWhatsAppClick = async () => {
        if (filteredPoints.length === 0 || currentIndex >= filteredPoints.length) return;

        const point = filteredPoints[currentIndex];
        let message = '';

        switch (messageType) {
          case "missingLocation":
            update_ubicacion_enviado(point.id);
            message = `¡Hola! Un paquete a nombre de ${point.recipient_name} está listo para ser entregado hoy entre la 2:00 p.m. y 8:00 p.m. por parte de ${point.user_name}. Por favor, escoge la ubicacion de entrega aqui: www.fastmaxlima.pe/#/${point.id}. ¡Gracias!`;
            break;
          case "missingLocationRepeat":
            message = 
            !point.latitude && !point.ubicacion_enviado
                ? `por favor escoge la ubicacion de entrega aqui: www.fastmaxlima.pe/#/${point.id}.`
                : `error`;
            break;
            case "orderArrival":
                update_encamino_recojo_enviado(point.id);
                // Take away 5 hours from the original arrival time
                const arrivalTime = dayjs(point.arrival_time).subtract(5, "hour");
            
                // Perform the 15-minute subtraction and addition after adjusting for the 5-hour difference
                const arrivalStart = arrivalTime
                  .subtract(15, "minute")
                  .format("HH:mm");
                const arrivalEnd = arrivalTime
                  .add(15, "minute")
                  .format("HH:mm");
            
                // Encode the URL for tracking
                const encodedUrlClient = `www.fastmaxlima.pe`;
            
                // Set the message with the new arrival window
                message = `Ya estamos en Ruta. La hora estimada de llegada es entre ${arrivalStart} y ${arrivalEnd}, es importante que tengas tus paquetes rotulados con el ID de entrega, nombre, direccion y distrito. Sigue al repartidor en tiempo real usando tu cuenta en: ${encodedUrlClient}`;
                break;
            
            case "deliveryArrival":
                update_encamino_enviado(point.id);
                // Take away 5 hours from the original arrival time
                const deliveryArrivalTime = dayjs(point.arrival_time).subtract(5, "hour");
            
                // Perform the 30-minute subtraction and addition after adjusting for the 5-hour difference
                const deliveryArrivalStart = deliveryArrivalTime
                  .subtract(55, "minute")
                  .format("HH:mm");
                const deliveryArrivalEnd = deliveryArrivalTime
                  .add(55, "minute")
                  .format("HH:mm");
            
                // Encode the URL for delivery tracking
                // const encodedUrlDelivery = `https://www.fastmaxlima.pe/#/${point.id}`;
            
                // Set the message with the new delivery window
                message = `Ya estamos en Ruta. La hora estimada de llegada es entre ${deliveryArrivalStart} y ${deliveryArrivalEnd}.`;
                break;
          case "missingLocationClient":
            message = `La ubicación de entrega para ${point.recipient_name} aún no ha sido seleccionada. Por favor, contacte a su cliente para ayudarnos a determinar la ubicación deseada para la entrega. ¡Gracias!`;
            break;
            case "orderArrivalTime":
            // Subtract 5 hours from the original arrival time
            const orderArrivalTreinta = dayjs(point.arrival_time).subtract(5, "hour");

            // Perform the 30-minute subtraction and addition after adjusting for the 5-hour difference
            const orderArrivalTreintaStart = orderArrivalTreinta
            .subtract(30, "minute")
            .format("HH:mm");
            const orderArrivalTreintaEnd = orderArrivalTreinta
            .add(30, "minute")
            .format("HH:mm");
            message =
            orderPoints[currentIndex].treinta_minutos && !orderPoints[currentIndex].treinta_minutos_enviado
                ? `El currier esta de camino, llegara entre 30 min y 1 hora aproximadamente, es importante que tengas tus paquetes rotulados con el ID de entrega, nombre, direccion y distrito, sigue al motorizado desde tu cuenta en: www.fastmaxlima.pe.`
                : `El currier esta de camino, la hora estimada de llegada es entre ${orderArrivalTreintaStart} y ${orderArrivalTreintaEnd}, es importante que tengas tus paquetes rotulados con el ID de entrega, nombre, direccion y distrito, sigue al motorizado desde tu cuenta en: www.fastmaxlima.pe.`;
            break;

        case "deliveryArrivalTime":
            // Subtract 5 hours from the original arrival time
            const deliveryArrivalTreinta = dayjs(point.arrival_time).subtract(5, "hour");

            // Perform the 30-minute subtraction and addition after adjusting for the 5-hour difference
            const deliveryArrivalTreintaStart = deliveryArrivalTreinta
            .subtract(30, "minute")
            .format("HH:mm");
            const deliveryArrivalTreintaEnd = deliveryArrivalTreinta
            .add(30, "minute")
            .format("HH:mm");

            message =
            point.treinta_minutos && !point.treinta_minutos_enviado
                ? `El currier esta de camino, llegara entre 30 min y 1 hora aproximadamente, sigue al motorizado aqui: www.fastmaxlima.pe/#/${point.id}.`
                : `Llegaremos en 3 minutos`;
            break;

            case "deliveryTresMinutes":
    
                message = `Llegaremos en 3 minutos`;
                break;

                case "charge":
                    update_cobrar_enviado(point.id);
                    message = `El monto a cobrar es de S/. ${point.charge}. Por favor, puedes pagar en efectivo(monto exacto), También aceptamos pagos a través de Yape y Plin. ¡Gracias!, los detalles de tu entrega aqui: www.fastmaxlima.pe/#/${point.id}`;
                    break;

            case "finished":
                update_terminado_enviado(point.id);
                const UrlFinal = `www.fastmaxlima.pe`;
                message = `¡Hola! Se realizaron tus entregas, revisa los detalles en tu cuenta: ${UrlFinal}`;
                break;
          default:
            return;
        }

        const phoneNumber = 
            (messageType === 'missingLocation' || 
            messageType === 'deliveryArrivalTime' ||
            messageType === 'deliveryArrival' ||
            messageType === 'charge' ||
            messageType === 'missingLocationRepeat') 
                ? point.recipient_phone 
                : (messageType === 'finished')
                    ? point.telefono 
                    : (messageType === 'missingLocationClient')
                        ? point.user_phone
                        : point.telefono
        window.open(`https://wa.me/+51${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
                console.log(`point number ${point.id} and phone number ${phoneNumber}`);
        // Update the status for Arrival Time messages
        if (messageType === 'orderArrivalTime' || messageType === 'deliveryArrivalTime') {
            try {
                const token = localStorage.getItem('token');
                await axios.post(`${process.env.REACT_APP_API_URL}/api/update_message_status`, {
                    id: point.id,
                    messageType
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } catch (error) {
                console.error("Error updating message status:", error);
            }
        }

        // Move to the next point
        setCurrentIndex(prevIndex => prevIndex + 1);
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
                    <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {filteredPoints.length > 0 && currentIndex < filteredPoints.length && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleWhatsAppClick}
                    sx={{
                        width: 300, // 3 times the original width (100 * 3)
                        height: 300, // 3 times the original height (100 * 3)
                        borderRadius: '50%', 
                        fontSize: 13,
                        display: 'flex',
                        // alignItems: 'flex-start', // Align text at the top
                        justifyContent: 'center', 
                        paddingTop: '20px', // Add some padding to bring the text down a bit from the top
                        backgroundColor: 'black', // Set background color to black
                        color: 'white', // Set text color to white for contrast
                        '&:hover': {
                            backgroundColor: 'black', // Maintain black background on hover
                        }
                    }}
                >
                    {filteredPoints[currentIndex]?.recipient_name || filteredPoints[currentIndex]?.customer_name || 'No Data'} {filteredPoints[currentIndex]?.id || filteredPoints[currentIndex]?.id || 'No Id'}
                </Button>
            )}
        </Box>
            <Box sx={{ width: '280px', borderLeft: '1px solid #ccc', padding: 2 }}>
                <Typography variant="h6" style={{ color: 'black' }}>Tipo de Mensaje</Typography>
                <List>
                    <ListItem button onClick={handleLocationClick}>
                        <ListItemText primary="Falta Ubicacion" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleLocationRepeatClick}>
                        <ListItemText primary="Falta Ubicacion Recordatorio" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleNoLocationClientClick}>
                        <ListItemText primary="Falta Ubicacion Cliente " style={{ color: 'black' }} />   
                    </ListItem>
                    <ListItem button onClick={handleOrderArrivalTime}>
                        <ListItemText primary="De camino a Recoger" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleDeliveryArrivalTime}>
                        <ListItemText primary="De camino a Entregar" style={{ color: 'black' }} />
                    </ListItem>
                    
                    <ListItem button onClick={handleOrderArrivalTimeClick}>
                        <ListItemText primary="Tiempo llegada Recojo" style={{ color: 'black' }} />
                    </ListItem>
                    
                    <ListItem button onClick={handleDeliveryArrivalTimeClick}>
                        <ListItemText primary="Tiempo llegada Entrega" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleDeliveryTresMinutesClick}>
                        <ListItemText primary="Llegada 3 minutos" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleChargeClick}>
                        <ListItemText primary="Monto a cobrar" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem button onClick={handleFinishedClick}>
                        <ListItemText primary="Termino servicio" style={{ color: 'black' }} />
                    </ListItem>
                </List>
            </Box>
        </Box>
    );
}

export default Mensajeria;
