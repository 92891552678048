import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

const Message = ({ userId, onBackClick }) => {
  const [deliveryPoints, setDeliveryPoints] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
      .then(response => {
        setDeliveryPoints(response.data.delivery_points || []);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [userId]);

  const openWhatsApp = (phoneNumber, id) => {
    const message = `¡Hola! Tu código de seguimiento es ${id}. Ingresa a www.fastmaxlima.pe en la sección 'Mi Entrega' para seguir tu paquete en tiempo real y obtener todos los detalles de tu entrega.`;
    const whatsappUrl = `https://wa.me/+51${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div style={{ padding: '20px', height: '600px', overflowY: 'auto' }}>
        <IconButton onClick={onBackClick} style={{ marginBottom: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </IconButton>
      <h2>Entregas</h2>
      <p style={{
    backgroundColor: '#f8d7da', 
    color: '#721c24', 
    border: '1px solid #f5c6cb', 
    padding: '15px', 
    borderRadius: '4px', 
    fontWeight: 'bold'
  }}>
  Haz clic en el receptor para enviarle el código único de seguimiento y permitirle seleccionar su ubicación de entrega en www.fastmaxlima.pe. Por seguridad, elegir la ubicación en la web es la única opción. Usa el código para que el destinatario pueda rastrear su paquete fácilmente. ¡Haz clic y envía el mensaje!
</p>      <ul style={{ listStyle: 'none', padding: 0 }}>
        {deliveryPoints.map(point => (
          <li
            key={point.id}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              borderBottom: '1px solid #ccc',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9',
              borderRadius: '4px',
              marginBottom: '10px'
            }}
            onClick={() => openWhatsApp(point.recipient_phone, point.id)}
          >
            <div>
              <strong>Codigo:</strong> {point.id} <br />
              <strong>Receptor:</strong> {point.recipient_name} <br />
              <strong>Telefono:</strong> {point.recipient_phone}
            </div>
            <WhatsAppIcon
              
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Message;
