import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import { districts } from '../user/Districs';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuDriver from "./MenuDriver";
import MarkerTextDriver from './MarkerTextDriver';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function WorkDriver({ setActiveComponent, userId, onBackClick, setMainDeliveryPoints, setMainOrder}) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [user, setUser] = useState(null);
  const [deliveryPoints, setDeliveryPoints] = useState([]);
  const [deliveryPointsMarkers, setDeliveryPointsMarkers] = useState([]);
  const [userMarker, setUserMarker] = useState(null);
  const [driver, setDriver] = useState(null);
  const [currentLocationMarker, setCurrentLocationMarker] = useState(null);
  const [orderPointsMarkers, setOrderPointsMarkers] = useState([]);
  const [driverMarker, setDriverMarker] = useState([]);
  const [showMarkers, setShowMarkers] = useState(false);
  const [orderPoints, setOrderPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [sequence, setSequence] = useState(0);
  const [updatedPoints, setUpdatedPoints] = useState([]);
  const [updatedOrders, setUpdatedOrders] = useState([]);
  const [showTextField, setShowTextField] = useState(false);
  const [selectedMapDeliveryPoint, setSelectedMapDeliveryPoint] = useState(null);
  const [showDeliveryPoint, setShowDeliveryPoint] = useState(false);
  const [selectedMapOrder, setSelectedMapOrder] = useState(null);
  const [showOrder, setShowOrder] = useState(false);
  


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/driver/${userId}`)
      .then(response => {
        setUser(response.data);
        console.log('user ', response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [userId]);

  useEffect(() => {
    if (user) {
      // Function to fetch delivery and order points
      const fetchData = () => {
        // Fetch delivery points
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/admin`)
          .then(response => {
            // Filter only points with status 'pending'
            const pendingDeliveryPoints = response.data.filter(point => point.status === 'pending');
            setDeliveryPoints(pendingDeliveryPoints);
            console.log('Pending delivery points: ', pendingDeliveryPoints);
          })
          .catch(error => console.error('Error fetching delivery points:', error));
  
        // Fetch order points
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/admin`)
          .then(response => {
            // Filter only points with status 'pending'
            const pendingOrderPoints = response.data.filter(point => point.status === 'pending');
            setOrderPoints(pendingOrderPoints);
            console.log('Pending order points: ', pendingOrderPoints);
          })
          .catch(error => console.error('Error fetching order points:', error));
      };
  
      // Call fetchData immediately on component mount
      fetchData();
  
      // Set up the interval to fetch data every 5 seconds (5000ms)
      const intervalId = setInterval(() => {
        fetchData();
      }, 5000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [user]);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/navigation-preview-night-v4',
        center: [-77.03711992646711, -12.060235303763926], // Default coordinates (Lima)
        zoom: 12
      });
  
      mapRef.current.on('load', () => {
        // initializeUserMarker();
        // // initializeDeliveryPointMarkers(); if necessary but doble markers are showing
        // fitMapToMarkers();
      });
    } else if (mapRef.current) {
      // initializeUserMarker();
      // initializeDeliveryPointsMarkers();
      // initializeOrderPointsMarkers();
      // fitMapToMarkers();
    }
  }, []);

  

  useEffect(() => {
    if (mapRef.current) {
      initializeDeliveryPointsMarkers();
      
    }
  }, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current) {
      initializeOrderPointsMarkers();
    }
  }, [orderPoints]);
  
  const fitMapToMarkers = () => {
    if (mapRef.current) {
      const bounds = new mapboxgl.LngLatBounds();
  
      // Add delivery points to bounds
      deliveryPoints.forEach(point => {
        if (point.longitude && point.latitude) {
          bounds.extend([point.longitude, point.latitude]);
        }
      });
    
      // Add order points to bounds
      orderPoints.forEach(point => {
        if (point.lon && point.lat) {
          bounds.extend([point.lon, point.lat]);
        }
      });
  
      // Fit the map to the bounds, with some padding
      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds, { padding: 50 });
      }
    }
  };

  const initializeDeliveriesMarkers = (points, setMarkers, markerShape) => {
    // Remove existing markers
    Object.values(markerShape === 'circle' ? deliveryPointsMarkers : orderPointsMarkers).forEach(marker => marker.remove());
    const newMarkers = {};
  
    points.forEach(point => {
      let { latitude, longitude } = point;
      let color;
  
      // Determine the marker color based on the status and conditions
      if (point.driver_id === userId) {
        color = 'purple';  // Color if the point's driver_id matches the userId
      } else if (point.is_here) {
        color = 'green';   // Color if the point is_here is true
      } else if (point.status === 'pending') {
        color = 'blue';    // Color for pending status
      } else {
        color = 'red';     // Default color is red
      }
  
      // Check if latitude and longitude are missing
      if (!latitude || !longitude) {
        // Look for the district data
        const districtData = districts[point.distric];
        if (districtData) {
          // Set latitude and longitude based on the district data
          latitude = districtData.latitude + (Math.random() - 0.5) * 0.01; // Random offset
          longitude = districtData.longitude + (Math.random() - 0.5) * 0.01; // Random offset
        }
      }
  
      // Only create a marker if we have valid coordinates
      if (latitude && longitude) {
        // Create a custom HTML element for the marker with the sequence number
        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.backgroundColor = color;
        markerElement.style.width = '20px';
        markerElement.style.height = '20px';
        markerElement.style.display = 'flex';
        markerElement.style.alignItems = 'center';
        markerElement.style.justifyContent = 'center';
        markerElement.style.color = 'white';
        markerElement.style.fontSize = '10px';
        markerElement.textContent = point.sequence; // Add the sequence number inside the marker
  
        // Apply different shapes for orders and deliveries
        if (markerShape === 'circle') {
          markerElement.style.borderRadius = '50%';
        } else if (markerShape === 'square') {
          markerElement.style.borderRadius = '0%';
        }
  
        const marker = new mapboxgl.Marker({
          element: markerElement
        })
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current);
  
          marker.getElement().addEventListener('click', () => {
            if (point.driver_id === userId) {
              handleChangeDriverClickBack(point.id);
            } else {
              handleChangeDriverClick(point.id);
            }
          });
  
        newMarkers[point.id] = marker;
      }
    });
  
    setMarkers(newMarkers);
  };

  const initializeOrdersMarkers = (points, setMarkers, markerShape) => {
    // Remove existing markers
    Object.values(markerShape === 'circle' ? deliveryPointsMarkers : orderPointsMarkers).forEach(marker => marker.remove());
    const newMarkers = {};
  
    points.forEach(point => {
      let { latitude, longitude } = point;
      let color;
  
      // Determine the marker color based on the status and conditions
      if (point.driver_id === userId) {
        color = 'purple';  // Color if the point's driver_id matches the userId
      } else if (point.is_here) {
        color = 'green';   // Color if the point is_here is true
      } else if (point.status === 'pending') {
        color = 'blue';    // Color for pending status
      } else {
        color = 'red';     // Default color is red
      }
  
      // Check if latitude and longitude are missing
      if (!latitude || !longitude) {
        // Look for the district data
        const districtData = districts[point.distric];
        if (districtData) {
          // Set latitude and longitude based on the district data
          latitude = districtData.latitude + (Math.random() - 0.5) * 0.01; // Random offset
          longitude = districtData.longitude + (Math.random() - 0.5) * 0.01; // Random offset
        }
      }
  
      // Only create a marker if we have valid coordinates
      if (latitude && longitude) {
        // Create a custom HTML element for the marker with the sequence number
        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.backgroundColor = color;
        markerElement.style.width = '20px';
        markerElement.style.height = '20px';
        markerElement.style.display = 'flex';
        markerElement.style.alignItems = 'center';
        markerElement.style.justifyContent = 'center';
        markerElement.style.color = 'white';
        markerElement.style.fontSize = '10px';
        markerElement.textContent = point.sequence; // Add the sequence number inside the marker
  
        // Apply different shapes for orders and deliveries
        if (markerShape === 'circle') {
          markerElement.style.borderRadius = '50%';
        } else if (markerShape === 'square') {
          markerElement.style.borderRadius = '0%';
        }
  
        const marker = new mapboxgl.Marker({
          element: markerElement
        })
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current);
  
          marker.getElement().addEventListener('click', () => {
            if (point.driver_id === userId) {
              handleChangeDriverOrderClickBack(point.id);
            } else {
              handleChangeDriverOrderClick(point.id);
            }
          });
  
        newMarkers[point.id] = marker;
      }
    });
  
    setMarkers(newMarkers);
  };

  const handleChangeDriverClick = (deliveryPointId) => {
   
    // Proceed with the API call if a valid ID was entered
    axios.post(`${process.env.REACT_APP_API_URL}/api/update/driver_id`, {
        id: deliveryPointId,  // The delivery point ID
        driver_id: userId
    })
    .then(response => {
        // alert("El conductor ha sido actualizado exitosamente.");
        // Optionally, update the UI or trigger a state refresh here
    })
    .catch(error => {
        console.error('Error updating driver:', error);
        alert('No se pudo actualizar el conductor.');
    });
  };
  const handleChangeDriverClickBack = (deliveryPointId) => {
   
    // Proceed with the API call if a valid ID was entered
    axios.post(`${process.env.REACT_APP_API_URL}/api/update/driver_id`, {
        id: deliveryPointId,  // The delivery point ID
        driver_id: '3'
    })
    .then(response => {
        // alert("El conductor ha sido actualizado exitosamente.");
        // Optionally, update the UI or trigger a state refresh here
    })
    .catch(error => {
        console.error('Error updating driver:', error);
        alert('No se pudo actualizar el conductor.');
    });
  };

  const handleChangeDriverOrderClick = (orderId) => {

    // Proceed with the API call if a valid ID was entered
    axios.post(`${process.env.REACT_APP_API_URL}/api/update_order/driver_id`, {
        id: orderId,  // The delivery point ID
        driver_id: userId
    })
    .then(response => {
        alert("El conductor ha sido actualizado exitosamente.");
        // Optionally, update the UI or trigger a state refresh here
    })
    .catch(error => {
        console.error('Error updating driver:', error);
        alert('No se pudo actualizar el conductor.');
    });
  };

  const handleChangeDriverOrderClickBack = (orderId) => {

    // Proceed with the API call if a valid ID was entered
    axios.post(`${process.env.REACT_APP_API_URL}/api/update_order/driver_id`, {
        id: orderId,  // The delivery point ID
        driver_id: '3'
    })
    .then(response => {
        // alert("El conductor ha sido actualizado exitosamente.");
        // Optionally, update the UI or trigger a state refresh here
    })
    .catch(error => {
        console.error('Error updating driver:', error);
        alert('No se pudo actualizar el conductor.');
    });
  };

  const initializeDeliveryPointsMarkers = () => {
    initializeDeliveriesMarkers(deliveryPoints, setDeliveryPointsMarkers, 'circle');
  };
  
  const initializeOrderPointsMarkers = () => {
    initializeOrdersMarkers(orderPoints, setOrderPointsMarkers, 'square');
  };


  
  return (
    <div className="map">
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "100vh" }}
      ></div>
      <div
        style={{
            position: "absolute",
            top: "25px",
            left: "25px",
            zIndex: 2000, // Ensure it stays on top
        }}
        onClick={onBackClick}
        >
        <img src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`} alt="Logo" style={{ height: "78px", width: "78px" }} />
        </div>

        <div
        style={{
          
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
      }}
        >
          </div>
          <div
        style={{
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
        }}
      >
      </div>
      
        </div>
    
  );
}

export default WorkDriver;
