import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

function About({ setCurrentComponent }) {


  const handleContactClick = () => {
    const whatsappUrl = `https://wa.me/51922835892`;
    window.open(whatsappUrl, "_blank");
  };
    return (
        <div className="row featurette" style={{ width: "100%" }}>
            {/* Carousel Section */}
            <div style={{ maxHeight: "70vh", overflow: "hidden", width: "100vw" }}>
                <Carousel
                    style={{
                        height: "100%", // Ensure the height is 100% of the container
                        width: "100%", // Ensure the width is 100% of the container
                        overflow: "hidden", // Prevent overflow
                    }}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={`${process.env.PUBLIC_URL}/grupo3.png`}
                            alt="Lima City"
                            style={{
                                objectFit: "cover", // Fill the space without distortion
                                height: "100%",
                                width: "90%",
                            }}
                        />
                        <Carousel.Caption
                            style={{
                                position: "absolute",
                                top: "55%", // Position the caption at the bottom
                                left: "10px", // Adjust position to the left
                                padding: "10px",
                                borderRadius: "5px",
                                color: "white",
                            }}
                        >
                            <h2>Sobre Fast Max</h2>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div class="p-4 md:p-0 w-full"></div>
            <div className="p-3 text-center bg-body-tertiary rounded-3" style={{ marginBottom: '0px' }}>
                    <p className="lead" style={{ marginBottom: '0' }}>
                        Fast Max fue fundado en 2020 con la misión de ayudar a las tiendas online a llegar a sus clientes de manera rápida, económica y eficiente. Desde nuestros inicios, hemos estado comprometidos con la innovación en el comercio electrónico, proporcionando soluciones de entrega que benefician tanto a los comerciantes como a los consumidores.
                    </p>
                </div>

                <div class="p-4 md:p-0 w-full"></div>
                <div class="p-4 md:p-0 w-full"></div>
            <div>
                <div className="row">
                    <div className="col-md-7" style={{ paddingLeft: "5%" }}>
                        <h2 className="featurette-heading fw-normal lh-1">
                            Creciendo con nuestros socios
                        </h2>
                        <p className="lead">
                            Trabajamos con las mejores tiendas y comercios en línea. Apoyamos a nuestros socios en cada paso del camino, impulsando su crecimiento y eficiencia. ¿Listo para llevar tu negocio al siguiente nivel?
                        </p>
                    </div>
                    <div className="col-md-5">
                        <img
                            src={`${process.env.PUBLIC_URL}/italiano3.png`}
                            alt="Lima City"
                            className="featurette-image img-fluid mx-auto"
                            width="500"
                            height="500"
                            style={{
                                objectFit: "cover", // Ensures the image fits the container nicely
                                maxHeight: "500px", // Ensure it doesn't exceed the max height
                            }}
                        />
                    </div>
                </div>
            </div>
            <div class="p-4 md:p-0 w-full"></div>
                <div class="p-4 md:p-0 w-full"></div>                  

            <div>
                <div className="row">
                    <div className="col-md-7" style={{ paddingLeft: "5%" }}>
                        <h2 className="featurette-heading fw-normal lh-1">
                        Nuestros repartidores son nuestros héroes
                        </h2>
                        <p className="lead">
                        Estamos aquí para ofrecer emocionantes oportunidades y brindar flexibilidad laboral. Conoce más sobre el recorrido de nuestros repartidores o regístrate para convertirte en uno de ellos.
                        </p>
                    </div>
                    <div className="col-md-5">
                        <img
                            src={`${process.env.PUBLIC_URL}/motorizado1.png`}
                            alt="Lima City"
                            className="featurette-image img-fluid mx-auto"
                            width="500"
                            height="500"
                            style={{
                                objectFit: "cover", // Ensures the image fits the container nicely
                                maxHeight: "500px", // Ensure it doesn't exceed the max height
                            }}
                        />
                    </div>
                </div>
            </div>
            <div class="p-4 md:p-0 w-full"></div>
                <div class="p-4 md:p-0 w-full"></div>                  

            <div>
                <div className="row">
                    <div className="col-md-7" style={{ paddingLeft: "5%" }}>
                        <h2 className="featurette-heading fw-normal lh-1">
                        Los clientes son nuestra prioridad
                        </h2>
                        <p className="lead">
                        Somos mucho más que solo entregas rápidas. Fast Max permite a los clientes seguir los pedidos de sus tiendas favoritas, evitar largas filas y recibir sus compras rápidamente. Las necesidades de los clientes están en el centro de nuestro negocio. Descarga la aplicación y experimenta la verdadera conveniencia.
                        </p>
                    </div>
                    <div className="col-md-5">
                        <img
                            src={`${process.env.PUBLIC_URL}/anders1.jpg`}
                            alt="Lima City"
                            className="featurette-image img-fluid mx-auto"
                            width="500"
                            height="500"
                            style={{
                                objectFit: "cover", // Ensures the image fits the container nicely
                                maxHeight: "500px", // Ensure it doesn't exceed the max height
                            }}
                        />
                    </div>
                </div>
            </div>
            <div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <footer className="footer bg-black text-white py-4">
  <div className="container" style={{ height: "400px"}}>
    {/* Footer Logo */}
    <div className="footer__logo mb-4 d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
        alt="FastMax Logo"
        width="40"
        height="40"
        className="me-2"
      />
      <span className="fs-4" style={{ color: 'white', fontWeight: 'bold' }}>
        Fast Max
      </span>
    </div>

    {/* Footer Links */}
    <div className="row">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('login')} className="text-white text-decoration-none">
              Ingresa
            </a>
          </li>
          <li className="mb-2">
            <a href="https://www.fastmaxlima.pe/nosotros" className="text-white text-decoration-none">
              Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('workWithUs')} className="text-white text-decoration-none">
              Trabaja con Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('yourDelivery')} className="text-white text-decoration-none">
              Tu Entrega
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleContactClick} className="text-white text-decoration-none">
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="row mt-4">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li>
            Copyright © 2024{' '}
            <a href="https://www.fastmaxlima.pe" target="_blank" className="text-white text-decoration-none">
              Fast Max
            </a>
          </li>
        </ul>
      </div>
      <div className="col-md-6 text-md-end">
        <ul className="list-unstyled">
          <li>
            <a
              href="https://www.fastmaxlima.pe/politica-de-privacidad"
              className="text-white text-decoration-none"
            >
              Política de Privacidad
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
        </div>
    )
};

export default About;
