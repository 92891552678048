import React from 'react';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';  
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';

const MarkerText = ({ point, onClose }) => {
    const { id, charge, charged, transfer, recipient_name, address, image_urls, notes, notes_driver, arrival_time, date_time_entrega } = point;

    // Default image paths
    const defaultImages = [
        `${process.env.PUBLIC_URL}/llamaFeliz.webp`
    ];

    const formatArrivalTime = (time) => {
        const date = new Date(time);
        const hour = date.getHours() - 5;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        // Calculate the time range (±1 hour)
        const startHour = (hour - 1 + 24) % 24; // To handle negative hours
        const endHour = (hour + 1) % 24;
        
        return `${startHour}:${minutes} - ${endHour}:${minutes}`;
    };

    // Use default images if no image_urls are provided
    const images = image_urls && image_urls.length > 0 ? image_urls : defaultImages;

    return (
        <Card sx={{ maxWidth: 275, position: 'relative' }}>
            <Carousel>
                {images.map((image, index) => (
                    <CardMedia
                        key={index}
                        component="img"
                        alt={`${recipient_name} image ${index + 1}`}
                        height="260"
                        sx={{ width: '80%', margin: '0 auto' }}
                        image={image}
                    />
                ))}
            </Carousel>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {recipient_name.substring(0, 17)} {id}
                </Typography>
                {arrival_time && (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                    <AccessTimeIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {date_time_entrega
                        ? new Date(date_time_entrega).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        : `${formatArrivalTime(arrival_time)} Aprox`}
                    </Typography>
                  </Box>
                )}

                {address && (
                    <Typography variant="body2" color="text.secondary">
                    {address.substring(0, 17)}, {charge}
                </Typography>
                )}
                {notes && ( 
                    <Typography variant="body2" color="text.secondary">
                     {notes.substring(0, 17)}
                </Typography>
                )}
                {notes_driver && (
                    <Typography variant="body2" color="text.secondary">
                        {notes_driver.substring(0, 17)}
                    </Typography>
                )}
                {charged && (
                    <Typography variant="body2" color="text.secondary">
                        {charged} // {transfer ? 'Transferido a fastmax' : ''}
                    </Typography>
                )}
            </CardContent>
            <IconButton
                size="medium"
                onClick={onClose}
                style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
            >
                <CloseIcon />
            </IconButton>
        </Card>
    );
};


export default MarkerText;