import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, TextField, Typography, List, ListItem, ListItemText, IconButton, Menu, MenuItem, Checkbox } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const ResumenAdmin = ({ onBackClick }) => {
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedDeliveries, setSelectedDeliveries] = useState([]);
    const [images, setImages] = useState([]);
    const [totalDay, setTotalDay] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [checkboxState, setCheckboxState] = useState({
        isHere: {}, // for the is_here checkboxes
        quinceSoles: {}, // for the quince_soles checkboxes
        veinteSoles: {} // for the veinte_soles checkboxes
    });
    

    useEffect(() => {
        const fetchResumenData = async () => {
            if (!selectedDate) return;
    
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resumen_data/admin`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { date: selectedDate }
                });
                console.log("Fetched Resumen Data:", response.data);
                setOrders(response.data.recojos);
                setDeliveryPoints(response.data.entregas);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };
    
        fetchResumenData();
    }, [selectedDate, currentOrderId]);

    useEffect(() => {
        // Initialize checkbox state for each type (is_here, quince_soles, and veinte_soles)
        const initialIsHereState = deliveryPoints.reduce((acc, point) => {
            acc[point.id] = point.is_here || false;
            return acc;
        }, {});
        
        const initialQuinceSolesState = deliveryPoints.reduce((acc, point) => {
            acc[point.id] = point.quince_soles || false;
            return acc;
        }, {});
    
        const initialVeinteSolesState = deliveryPoints.reduce((acc, point) => {
            acc[point.id] = point.veinte_soles || false;
            return acc;
        }, {});
    
        setCheckboxState({
            isHere: initialIsHereState,
            quinceSoles: initialQuinceSolesState,
            veinteSoles: initialVeinteSolesState
        });
    }, [deliveryPoints]);

    const handleCheckboxClick = (deliveryPointId, isChecked) => {
        setCheckboxState(prevState => ({
            ...prevState,
            isHere: { ...prevState.isHere, [deliveryPointId]: isChecked }
        }));
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/update_is_here`, {
            id: deliveryPointId,
            is_here: isChecked
        })
        .then(response => {
            console.log("Updated is_here:", response.data);
        })
        .catch(error => {
            console.error("Error updating is_here:", error);
        });
    };
    
    const handleCheckboxQuinceClick = (deliveryPointId, isChecked) => {
        setCheckboxState(prevState => ({
            ...prevState,
            quinceSoles: { ...prevState.quinceSoles, [deliveryPointId]: isChecked }
        }));
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/update_quince_soles`, {
            id: deliveryPointId,
            quince_soles: isChecked
        })
        .then(response => {
            console.log("Updated quince_soles:", response.data);
        })
        .catch(error => {
            console.error("Error updating quince_soles:", error);
        });
    };
    
    const handleCheckboxVeinteClick = (deliveryPointId, isChecked) => {
        setCheckboxState(prevState => ({
            ...prevState,
            veinteSoles: { ...prevState.veinteSoles, [deliveryPointId]: isChecked }
        }));
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/update_veinte_soles`, {
            id: deliveryPointId,
            veinte_soles: isChecked
        })
        .then(response => {
            console.log("Updated veinte_soles:", response.data);
        })
        .catch(error => {
            console.error("Error updating veinte_soles:", error);
        });
    };
    
    useEffect(() => {
        // Calculate the total income for the day
        let total = 0;
        orders.forEach(order => {
            const associatedDeliveries = deliveryPoints.filter(dp => dp.user_id === order.customer_id && dp.status === 'entregado');
            const numDeliveries = associatedDeliveries.length;
            if (numDeliveries === 1) {
                total += 15; // One delivery point costs 15 soles
            } else if (numDeliveries > 1) {
                total += numDeliveries * 9; // More than one delivery point costs 9 soles each
            }
        });
        setTotalDay(total);
    }, [orders, deliveryPoints]);

    const handleDownloadExcel = () => {
        // Define the columns you want in the Excel file
        const worksheetData = deliveryPoints.map(dp => ({
          id: dp.id,
          recipient_name: dp.recipient_name,
          user_name: dp.user_name,
          user_phone: dp.user_phone,
          address: dp.address,
          recipient_phone: dp.recipient_phone,
          district: dp.district,
          status: dp.status,
          charge: dp.charge,
          charged: dp.charged,
          driver_id: dp.driver_id,
          notes_driver: dp.notes_driver,
          notes: dp.notes
        }));
    
        // Create a new workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    
        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DeliveryPoints');
    
        // Write the Excel file and save it
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'DeliveryPoints.xlsx');
      };

      const handleOrderClick = (order) => {
        setSelectedOrder(order);
        
        // Filter delivery points associated with the selected order
        const relevantDeliveries = deliveryPoints.filter(dp => dp.user_id === order.customer_id);
        setSelectedDeliveries(relevantDeliveries);
        setImages([]);  // Clear images when selecting a new order
    
        // List of districts with a delivery price of 10 soles
        const tenSolesDistricts = [
            'La Molina', 'Ate', 'Bellavista', 'Carmen de la Legua', 'Carabayllo', 'Comas', 'Chorrillos', 
            'La Perla', 'La Punta', 'Puente Piedra', 'San Juan de Miraflores', 'San Juan de Lurigancho', 
            'Ventanilla', 'Villa El Salvador', 'Villa Maria del Triunfo'
        ];
        
        const deliveredPoints = relevantDeliveries.filter(dp => dp.status === 'entregado');
        const numDeliveries = deliveredPoints.length;
        let total = 0;
        let totalDeposit = 0;
        
        // Custom pricing based on user_id
        const specialPricing = {
            13: 7,  // For user_id 13, each delivery costs 7 soles
            53: 7,  // For user_id 53, each delivery costs 7 soles
            62: 10, // For user_id 62, each delivery costs 10 soles
        };
        
        // Calculate total price for each delivery point
        deliveredPoints.forEach(dp => {
            let price = 8; // Default price is 8 soles
    
            // Check for special district prices (10 soles for specific districts)
            if (tenSolesDistricts.includes(dp.district)) {
                price = 10;
            }
    
            // Check if veinte_soles or quince_soles flag is set
            if (dp.veinte_soles) {
                price = 20;
            } else if (dp.quince_soles) {
                price = 15;
            }
    
            // Special case for user_id 90: if they have only one delivery point, charge regular price (8 or 10 soles)
            if (dp.user_id === 90 && numDeliveries === 1) {
                price = tenSolesDistricts.includes(dp.district) ? 10 : 8;
            } 
    
            // Check for other specific user-based pricing (except for user 90 which has already been handled)
            if (specialPricing[dp.user_id]) {
                price = specialPricing[dp.user_id];
            }

            // General rule: if only one delivery, price is 15 soles (except for special cases like user 90, 13, 53, and 62)
            if (numDeliveries === 1 && ![13, 53, 62, 90].includes(dp.user_id) && !dp.veinte_soles) {
                price = 15;
            }
    
            // Add delivery price to total
            total += price;
        });
    
        // Calculate totalDeposit based on the 'charged' field from the relevant deliveries
        totalDeposit = deliveredPoints.reduce((acc, dp) => acc + dp.charged, 0);
    
        // Set the totalOrder and totalDeposit state
        setTotalOrder(total);
        setTotalDeposit(totalDeposit);
    };
    

    const deliveryCancel = deliveryPoints.filter(deliveryPoint => deliveryPoint.status === 'cancelado');

    const handleDeliveryPointClick = (deliveryPoint) => {
        setImages(deliveryPoint.image_urls || []);
    };

    const handleStatusChangeClick = (event, orderId) => {
        setAnchorEl(event.currentTarget);
        setCurrentOrderId(orderId);
    };

    const handleStatusChange = async (status) => {
        if (!currentOrderId) return;

        const token = localStorage.getItem("token");

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/status_change`, {
                orderId: currentOrderId,
                status: status
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setOrders(prevOrders => prevOrders.map(order =>
                order.id === currentOrderId ? { ...order, status: status } : order
            ));
        } catch (error) {
            console.error("Error updating status:", error);
        } finally {
            setAnchorEl(null);
            setCurrentOrderId(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentOrderId(null);
    };
    
    return (
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", padding: 2 }}>
        
        <Box sx={{ marginBottom: 2 }}>
        <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
          <TextField
            label="Pick Date"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ marginTop: 2 }}
          />
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1 }}>
    <Box sx={{ width: "30%", overflowY: "auto", padding: 2, maxHeight: '700px' }}>
        <Typography variant="h6" color="text.primary">Resumen del Día</Typography>
        <Typography variant="body1" color="text.primary">Recojos: {orders.length} Entregas:{deliveryPoints.length} Cacelado:{deliveryCancel.length}</Typography>
        <Typography variant="body1" color="text.primary">Total de Ingreso: {totalDay}</Typography>
        <FileCopyIcon onClick={handleDownloadExcel} style={{ color: 'black' }}/>
        <Typography variant="h6" sx={{ marginTop: 2 }} color="text.primary">Lista de Recojos</Typography>
        
        <List>
        {orders.map((order) => (
                <ListItem key={order.id} onClick={() => handleOrderClick(order)}>
                    <ListItemText
                        primary={`Recojo ${order.id}-${order.customer_id}: ${order.customer_name} - ${order.status}`}
                        primaryTypographyProps={{ color: "text.primary" }}
                    />
                    <IconButton
                        color="secondary"
                        onClick={(event) => handleStatusChangeClick(event, order.id)}
                    >
                        <AttachMoneyIcon />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    </Box>
    <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleStatusChange('pagado')}>Pagado</MenuItem>
                <MenuItem onClick={() => handleStatusChange('adeuda')}>Adeuda</MenuItem>
                <MenuItem onClick={() => handleStatusChange('depositar')}>Depositar</MenuItem>
                <MenuItem onClick={() => handleStatusChange('cancelado')}>Cancelado</MenuItem>
            </Menu>

            <Box sx={{ width: "30%", overflowY: "auto", padding: 2, maxHeight: '700px' }}>
    <Typography variant="h6" color="text.primary">Recojo Seleccionado</Typography>
    {selectedOrder ? (
        <>
            <List>
                <ListItem>
                    <ListItemText
                        primary={` ${selectedOrder.customer_name} - ${selectedOrder.status} - ${totalOrder} - $${totalDeposit} - ${totalDeposit - totalOrder}`}
                        primaryTypographyProps={{ color: "text.primary" }}
                    />
                </ListItem>
            </List>
            <Typography variant="h6" color="text.primary">Entregas Asociadas</Typography>
            <List>
                        {selectedDeliveries.map((deliveryPoint) => (
                            <ListItem key={deliveryPoint.id} button onClick={() => handleDeliveryPointClick(deliveryPoint)}>
                                <ListItemText
                                    primary={`Id: ${deliveryPoint.id} - ${deliveryPoint.recipient_name} - ${deliveryPoint.status}`}
                                    primaryTypographyProps={{ color: "text.primary" }}
                                />
                                <Checkbox
                                    checked={checkboxState.isHere[deliveryPoint.id] || false}
                                    onChange={(e) => handleCheckboxClick(deliveryPoint.id, e.target.checked)}
                                    color="primary"
                                />
                                <Checkbox
                                    checked={checkboxState.quinceSoles[deliveryPoint.id] || false}
                                    onChange={(e) => handleCheckboxQuinceClick(deliveryPoint.id, e.target.checked)}
                                    color="primary"
                                />
                                <Checkbox
                                    checked={checkboxState.veinteSoles[deliveryPoint.id] || false}
                                    onChange={(e) => handleCheckboxVeinteClick(deliveryPoint.id, e.target.checked)}
                                    color="primary"
                                />
                            </ListItem>
                        ))}
                    </List>
        </>
    ) : (
        <Typography variant="body2" color="text.primary">
            Seleccione un recojo para ver los detalles.
        </Typography>
    )}
</Box>


          <Box sx={{ width: "35%", overflowY: "auto", padding: 2 }}>
            <Typography variant="h6" color="text.primary">Imágenes</Typography>
            {images.length > 0 ? (
              <Carousel>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Delivery Image ${index + 1}`}
                    style={{ width: "100%", height: "350px", objectFit: "cover" }}
                  />
                ))}
              </Carousel>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No hay imágenes disponibles.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
};

export default ResumenAdmin;
