import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DownloadExcelResumen } from './DownloadExcelResumen';
import esLocale from 'date-fns/locale/es';


function PendingList({ onEditClick, userId, onBackClick }) {
    const [orders, setOrders] = useState([]);
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [deleteType, setDeleteType] = useState('order');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
          .then(response => {
            setDeliveryPoints(response.data.delivery_points || []);
          })
          .catch(error => console.error('Error fetching data:', error));
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${userId}`)
            .then(response => {
                setOrders(response.data.orders);
            })
            .catch(error => console.error("Error fetching orders:", error));
    }, [deliveryPoints]);

    const handleEditClick = (dp) => {
        setSelectedDeliveryPoint(dp);
        onEditClick(dp);
    };

    const handleDeleteClick = (id, type) => {
        setItemToDelete(id);
        setDeleteType(type);
        setOpenDeleteModal(true);
    };

    const handleDownloadResumenClick = () => {
        DownloadExcelResumen(deliveryPoints);
      };

    const handleDeleteConfirm = async () => {
        try {
            if (deleteType === 'order') {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_order/${itemToDelete}`
                );
                setOrders(orders.filter(order => order.id !== itemToDelete));
            } else {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_delivery_point/${itemToDelete}`
                );
                setDeliveryPoints(deliveryPoints.filter(dp => dp.id !== itemToDelete));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setOpenDeleteModal(false);
        }
    };

    const handleDeleteCancel = () => {
        setOpenDeleteModal(false);
    };

    const peruTimeZone = 'America/Lima';
    const nowInPeru = utcToZonedTime(new Date(), peruTimeZone);
    const currentDate = format(nowInPeru, 'yyyy-MM-dd');
    const currentDay = format(nowInPeru, 'EEEE', { locale: esLocale });

    const currentDayDeliveryPoints = deliveryPoints.filter(dp => {
        const deliveryDate = utcToZonedTime(new Date(dp.delivery_date), peruTimeZone);
        return format(deliveryDate, 'yyyy-MM-dd') === currentDate;
    });

    return (
        <div>
            <IconButton onClick={onBackClick}>
                <ArrowBackIcon />
            </IconButton>
            <h1 style={{ color: 'black' }}>{currentDay} {format(nowInPeru, 'dd-MM')}</h1>
            <h2 style={{ color: 'black' }}>Recojos</h2>
            <IconButton
                style={{
                position: "absolute",
                bottom: "88%",
                right: 30,
                zIndex: 1000,
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "50%",
                color: "black",
                marginBottom: "10px",
                transform: "scale(0.7)",
                }}
                onClick={handleDownloadResumenClick}
            >
                <FileCopyIcon />
            </IconButton>
            <List>
                {orders.map(order => (
                    <ListItem key={order.id}>
                        <ListItemAvatar>
                            <Avatar>
                                <HomeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                        primary={
                            <span style={{ color: 'black' }}>
                            {`${order.customer_name} - ID: ${order.id} - Fecha: ${new Date(order.recojo_date).toLocaleDateString('es-ES', {
                                month: '2-digit',
                                day: '2-digit'
                            })}`}
                            </span>
                        }
                        secondary={`${order.address.address} - ${order.order_notes}`}
                        />
                        <ListItemSecondaryAction>
                            {order.status !== 'recogido' && (
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(order.id, 'order')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <h2 style={{ color: 'black' }}>Entregas</h2>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <List>
                    {deliveryPoints.map(dp => (
                        <ListItem key={dp.id} >
                            <ListItemAvatar>
                            <LocationOnIcon style={{ color: 
                                dp.status === 'entregado' ? 'green' : 
                                dp.status === 'pending' ? 'gray' : 
                                dp.status === 'cancelado' ? 'red' : 
                                dp.status === 'encamino' ? 'blue' : 
                                'black' // default color for other statuses
                            }} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <span style={{ color: 'black' }}>
                                        {`${dp.recipient_name.substring(0, 15)} - ID: ${dp.id}${dp.charge ? ` - Cobrar S/. ${dp.charge}` : ''}`}
                                    </span>
                                }
                                secondary={
                                    `${dp.notes ? dp.notes.substring(0, 15) : ''} - 
                                     ${dp.address ? dp.address.substring(0, 15) : ''}`
                                  }
                            />
                            <ListItemSecondaryAction>
                            {dp.status !== 'entregado' && dp.status !== 'encamino' && dp.status !== 'pospuesto' && (
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(dp)}>
                                    <EditIcon />
                                </IconButton>
                                )}
                                {dp.status !== 'entregado' && dp.status !== 'encamino' && dp.status !== 'pospuesto' && (
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(dp.id, 'delivery')}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>
            <Dialog
                open={openDeleteModal}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este {deleteType === 'order' ? 'recojo' : 'punto de entrega'}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PendingList;