import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, Carousel } from "react-bootstrap";
import About from "./web/About";
import WorkWithUs from "./web/WorkWithUs";
import YourDelivery from "./web/YourDelivery";
import Cobertura from "./web/Cobertura";
import Input from "./Input";
import axios from "axios";
import { set } from "date-fns";

function Main({ onLoginSuccess, setIsUserRegistered }) {
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("home");
  const mainContentRef = useRef(null);

  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [currentComponent]);

  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData(prevLoginData => ({
      ...prevLoginData,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData);
      if (response.data.access_token && response.data.refresh_token) {  // Ensure there's an access_token in the response
        localStorage.setItem('token', response.data.access_token);  // Store the token
        localStorage.setItem('refresh_token', response.data.refresh_token);  // Store the refresh token
        localStorage.setItem('user_id', response.data.user_id);  // Store the user ID
        localStorage.setItem('username', response.data.username);  // Store the username
        localStorage.setItem('email', response.data.email);  // Store the email
        localStorage.setItem('phone_number', response.data.phone_number);  // Store the phone number
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('addresses', JSON.stringify(response.data.addresses));
        
        onLoginSuccess(response.data.user_id, response.data.username, response.data.email, response.data.phone_number, response.data.role, response.data.addresses);  // Call onLoginSuccess with the user ID
      } else {
        console.error("No token received");
      }
    } catch (error) {
      if (error.response) {
        console.error("Login failed:", error.response.data.message);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleAboutClick = () => {  
    setCurrentComponent("about");
    setIsNavbarExpanded(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleHomeClick = () => {
    setCurrentComponent("home");
    setIsNavbarExpanded(false);
    window.scrollTo(0, 0);
  };

  const handleLoginClick = () => {
    setCurrentComponent("login");
    setIsNavbarExpanded(false);
    window.scrollTo(0, 0);
  };


  const handleWorkWithUsClick = () => {
    setCurrentComponent("workWithUs");
    setIsNavbarExpanded(false);
    window.scrollTo(0, 0);
  };

  const handleYourDeliveryClick = () => {
    setCurrentComponent("yourDelivery");
    setIsNavbarExpanded(false);
  };

  const handleCoverageClick = () => {
    setCurrentComponent("cobertura");
    setIsNavbarExpanded(false);
    window.scrollTo(0, 0);
  };

  const handleContactClick = () => {
    const whatsappUrl = `https://wa.me/51977689676`;
    window.open(whatsappUrl, "_blank");
  };



  const handleToggle = () => {
    setIsNavbarExpanded(!isNavbarExpanded); // Toggle navbar expanded state
  };

  return (
    <div className="main-layout" style={{ overflowY: 'auto', height: '100vh', width: '100vw', backgroundColor: 'white' }}>
      {/* Navbar Section */}
      <Navbar
        bg="white"
        expand="lg"
        expanded={isNavbarExpanded} // Control the expansion of the navbar
        onToggle={handleToggle} // Handle toggle changes
        className="justify-content-between"
        style={{ position: "absolute", top: 0, width: "100%", backgroundColor: "white" }}
      >
        <Container fluid>
          {/* Logo and Brand */}
          <Navbar.Brand href="/">
            <img
              src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
              alt="FastMax Logo"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
            <span className="fs-4" style={{ color: 'rgb(255, 43, 133)', fontWeight: 'bold' }}>
              Fast Max
            </span>
          </Navbar.Brand>

          {/* Burger Menu Toggle */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} style={{ border: "none", outline: "none" }} />

          {/* Navbar Links */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={handleLoginClick} className="nav-link active" style={{ fontSize: '1.1rem', fontWeight: 'bold', color: 'black' }}>
                Ingresa
              </Nav.Link>
              <Nav.Link onClick={handleHomeClick} style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>Inicio</Nav.Link>
              <Nav.Link onClick={handleAboutClick} style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>Nosotros</Nav.Link>
              <Nav.Link onClick={handleWorkWithUsClick} style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>Trabaja con Nosotros</Nav.Link>
              <Nav.Link onClick={handleYourDeliveryClick} style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>Tu entrega</Nav.Link>
              <Nav.Link onClick={handleCoverageClick} style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>Cobertura</Nav.Link>
                      </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      { currentComponent === 'home' && (<main
        ref={mainContentRef}
        style={{
          marginTop: isNavbarExpanded ? "40vh" : "10vh", // Adjust margin based on navbar state
          transition: "margin-top 0.3s ease", // Smooth transition
          width: "100vw", // Make sure the main content takes full width of the viewport
          overflowY: "auto", // Allow overflow for content
        }}
      >
        {/* Bootstrap Carousel */}
        <div style={{ maxHeight: "70vh", overflow: "hidden", width: "100vw" }}>
          <Carousel
            style={{
              height: "100%", // Ensure the height is 100% of the container
              width: "100%", // Ensure the width is 100% of the container
              overflow: "hidden", // Prevent overflow
            }}
          >
            {/* First Slide */}
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/city5.jpg`}
                alt="Lima City"
                style={{
                  objectFit: "cover", // Fill the space without distortion
                  height: "100%",
                  width: "90%",
                }}
              />
              <Carousel.Caption
                style={{
                  position: "absolute",
                  top: !isNavbarExpanded ? "20%" : "20px", // Position the caption at the bottom
                  left: "10px", // Adjust position to the left
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                <h3>La App con mayor cobertura de Lima</h3>
              </Carousel.Caption>
            </Carousel.Item>
            {/* First Slide */}
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/touchScreen.jpg`}
                alt="Lima City"
                style={{
                  objectFit: "cover", // Fill the space without distortion
                  height: "100%",
                  width: "90%",
                }}
              />
              <Carousel.Caption
                style={{
                  position: "absolute",
                  top: !isNavbarExpanded ? "20%" : "20px", // Position the caption at the bottom
                  left: "10px", // Adjust position to the left
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                <h3>Facil de usar</h3>
              </Carousel.Caption>
            </Carousel.Item>

            {/* Second Slide */}
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/italiano2.png`}
                alt="Touch Screen"
                style={{
                  objectFit: "cover", // Fill the space without distortion
                  height: "100%",
                  width: "90%",
                }}
              />
              <Carousel.Caption
                style={{
                  position: "absolute",
                  top: !isNavbarExpanded ? "20%" : "20px",
                  left: "10px", // Adjust position to the left
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                <h3>Cobramos por ti.</h3>
              </Carousel.Caption>
            </Carousel.Item>
            {/* Thirt Slide */}
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/laptop2.jpg`}
                alt="Laptop image"
                style={{
                  objectFit: "cover", // Fill the space without distortion
                  height: "100%",
                  width: "90%",
                }}
              />
              <Carousel.Caption
                style={{
                  position: "absolute",
                  top: !isNavbarExpanded ? "15%" : "15px", // Position the caption at the bottom
                  left: "10px", // Adjust position to the left
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                <h3>Dedicate a vender</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div style={{padding: "10px;", color: "white" }}>
          <h2>¿Por qué elegirnos?</h2>
      </div>
      
      <div class="section--inner md:w-full">
                    <div class="p-4 md:p-0 w-full">
                            </div>
        
        <div class=" m-4 md:m-0 grid-cols-1">
                                                <h2 class="pt-12">Con Fast Max, dedica tu tiempo a lo que amas. Nosotros nos encargamos de tus entregas con seguimiento en tiempo real y resúmenes al instante.</h2>
                                    </div>
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <section class="section__image-text pt-8 md:pt-0 md:my-0 w-full bg-white in-view">
  <div class="content-width md:py-16 md:flex md:flex-wrap md:items-center justify-between">
    
    <div class="md:w-1/2 md:pr-8 lg:pr-16 mt-8 md:mt-0 flex flex-col items-start justify-center md:order-1 md:pl-16">
      <aside class="p-4 md:p-0">
        <h2 class="text-lg md:text-2xl lg:text-3xl font-semibold">
          ¡Conoce la próxima generación de entregas!
        </h2>
        <p class="pt-4">
          ¿Qué hace que el comercio rápido sea rápido? Nos destacamos por ofrecer soluciones de entrega personalizadas y ágiles para nuestros clientes, socios y empleados. Descubre cómo impulsamos estilos de vida auténticos y fomentamos la innovación en todo el mundo.
        </p>
        <div class="pt-4">
        <a onClick={handleAboutClick}>
          <button
            style={{
              backgroundColor: 'rgb(255, 43, 133)',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              borderRadius: '50px', // Rounded corners
              padding: '10px 20px', // Spacing around the text
              fontSize: '1rem',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease' // Smooth transition effect on hover
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(230, 30, 120)')} // Hover effect
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(255, 43, 133)')} // Reset on mouse leave
          >
            Lee nuestra historia
          </button>
        </a>
        </div>
      </aside>
    </div>
    
    <div class="md:w-1/2 w-full mt-8 md:mt-0">
      <div class="w-full">
        <img
          class="d-block w-100"
          src={`${process.env.PUBLIC_URL}/appLogo.png`}
          alt="Lima City"
          style={{
            objectFit: "cover",
            height: "auto",
            width: "100%"
          }}
        />
      </div>
    </div>
    
  </div>
</section>
<div class="p-4 md:p-0 w-full">
    </div>
    <section class="section__image-text pt-8 md:pt-0 md:my-0 w-full bg-white in-view">
  <div class="content-width md:py-16 md:flex md:flex-wrap md:items-center justify-between">
    
    <div class="md:w-1/2 md:pr-8 lg:pr-16 mt-8 md:mt-0 flex flex-col items-start justify-center md:order-1 md:pl-16">
      <aside class="p-4 md:p-0">
        <h2 class="text-lg md:text-2xl lg:text-3xl font-semibold">
        Descubre la conveniencia.
        </h2>
        <p class="pt-4">
        ¡Pruébanos! Entregas rápidas y eficientes para tu tienda en línea, a solo un clic de distancia. ¡Empieza ahora y observa cómo tus pedidos llegan a tus clientes en tiempo récord!
        </p>
      </aside>
    </div>
    
    <div class="md:w-1/2 w-full mt-8 md:mt-0">
      <div class="w-full">
        <img
          class="d-block w-100"
          src={`${process.env.PUBLIC_URL}/van1.png`}
          alt="Lima City"
          style={{
            objectFit: "cover",
            height: "auto",
            width: "100%"
          }}
        />
      </div>
    </div>
    
  </div>
</section>
<div class="p-4 md:p-0 w-full">
    </div>
    <section class="section__image-text pt-8 md:pt-0 md:my-0 w-full bg-white in-view">
  <div class="content-width md:py-16 md:flex md:flex-wrap md:items-center justify-between">
    
    <div class="md:w-1/2 md:pr-8 lg:pr-16 mt-8 md:mt-0 flex flex-col items-start justify-center md:order-1 md:pl-16">
      <aside class="p-4 md:p-0">
        <h2 class="text-lg md:text-2xl lg:text-3xl font-semibold">
        Sigue tu entrega en tiempo real con tu código.
        </h2>
        <p class="pt-4">
        Introduce tu código único y sigue el recorrido de tu paquete en tiempo real. Con nuestra plataforma, tendrás acceso inmediato al estado de tu entrega, desde la recolección hasta la llegada a tu puerta. ¡Mantente informado y tranquilo con cada envío!        </p>
        <div class="pt-4">
        <a onClick={handleYourDeliveryClick}>
  <button
    style={{
      backgroundColor: 'rgb(255, 43, 133)',
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
      borderRadius: '50px', 
      padding: '10px 20px', 
      fontSize: '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease'
    }}
    onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(230, 30, 120)')}
    onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(255, 43, 133)')}
  >
    Mi entrega
  </button>
</a>
        </div>
      </aside>
    </div>
    
    <div class="md:w-1/2 w-full mt-8 md:mt-0">
      <div class="w-full">
        <img
          class="d-block w-100"
          src={`${process.env.PUBLIC_URL}/italiano1.png`}
          alt="Lima City"
          style={{
            objectFit: "cover",
            height: "auto",
            width: "100%"
          }}
        />
      </div>
    </div>
    
  </div>
</section>
<div class="p-4 md:p-0 w-full">
    </div>
    <section class="section__image-text pt-8 md:pt-0 md:my-0 w-full bg-white in-view">
  <div class="content-width md:py-16 md:flex md:flex-wrap md:items-center justify-between">
    
    <div class="md:w-1/2 md:pr-8 lg:pr-16 mt-8 md:mt-0 flex flex-col items-start justify-center md:order-1 md:pl-16">
      <aside class="p-4 md:p-0">
        <h2 class="text-lg md:text-2xl lg:text-3xl font-semibold">
        Encuentra el trabajo que amas.
        </h2>
        <p class="pt-4">
        Encuentra el trabajo que amas. Con nuestra app, entregar paquetes nunca fue tan fácil. Solo necesitas tu teléfono, elige tus rutas y comienza a ganar haciendo entregas rápidas y eficientes. Tú decides cuándo y dónde trabajar, ¡todo al alcance de tu mano!
        </p>
        <div class="pt-4">
        <a onClick={handleWorkWithUsClick}>
  <button
    style={{
      backgroundColor: 'rgb(255, 43, 133)',
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
      borderRadius: '50px', 
      padding: '10px 20px', 
      fontSize: '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease'
    }}
    onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(230, 30, 120)')}
    onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(255, 43, 133)')}
  >
    Únete a nosotros
  </button>
</a>
        </div>
      </aside>
    </div>
    
    <div class="md:w-1/2 w-full mt-8 md:mt-0">
      <div class="w-full">
        <img
          class="d-block w-100"
          src={`${process.env.PUBLIC_URL}/moto2.png`}
          alt="Lima City"
          style={{
            objectFit: "cover",
            height: "auto",
            width: "100%"
          }}
        />
      </div>
    </div>
    
  </div>
</section>
<div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <footer className="footer bg-black text-white py-4">
  <div className="container" style={{ height: "400px"}}>
    {/* Footer Logo */}
    <div className="footer__logo mb-4 d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
        alt="FastMax Logo"
        width="40"
        height="40"
        className="me-2"
      />
      <span className="fs-4" style={{ color: 'white', fontWeight: 'bold' }}>
        Fast Max
      </span>
    </div>

    {/* Footer Links */}
    <div className="row">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li className="mb-2">
            <a onClick={handleLoginClick} className="text-white text-decoration-none">
              Ingresa
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleAboutClick} className="text-white text-decoration-none">
              Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleHomeClick} className="text-white text-decoration-none">
              Inicio
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleWorkWithUsClick} className="text-white text-decoration-none">
              Trabaja con Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleYourDeliveryClick} className="text-white text-decoration-none">
              Tu Entrega
            </a>
          </li>
          <li className="mb-2">
            <a onClick={handleContactClick} className="text-white text-decoration-none">
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="row mt-4">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li>
            Copyright © 2024{' '}
            <a href="https://www.fastmaxlima.pe" target="_blank" className="text-white text-decoration-none">
              Fast Max
            </a>
          </li>
        </ul>
      </div>
      <div className="col-md-6 text-md-end">
        <ul className="list-unstyled">
          <li>
            <a
              href="https://www.fastmaxlima.pe/politica-de-privacidad"
              className="text-white text-decoration-none"
            >
              Política de Privacidad
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
      </main>
    )}
    { currentComponent === 'about' && (
      <main 
      ref={mainContentRef}
      style={{
        marginTop: isNavbarExpanded ? "38vh" : "10vh", // Adjust margin based on navbar state
        transition: "margin-top 0.3s ease", // Smooth transition
        width: "100vw", // Make sure the main content takes full width of the viewport
        overflowY: "auto", // Allow overflow for content
      }}>
      < About setCurrentComponent={setCurrentComponent}/>
      </main>
    )}
    { currentComponent === 'login' && (
      <main style={{
        marginTop: isNavbarExpanded ? "38vh" : "10vh", // Adjust margin based on navbar state
        transition: "margin-top 0.3s ease", // Smooth transition
        width: "100vw", // Make sure the main content takes full width of the viewport
        overflowY: "auto", // Allow overflow for content
      }}>
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0'
      }}
    >
      <div style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
        
        <form
          className="form"
          onSubmit={handleSubmit}
          style={{
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            textAlign: 'center'
          }}
        >
          <h2 style={{ marginBottom: '20px', fontSize: '24px', color: '#333' }}>Login</h2>
          <Input
            name="email"
            type="text"
            placeholder="Email"
            value={loginData.email}
            onChange={handleChange}
            required
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '5px',
              fontSize: '16px',
              boxSizing: 'border-box',
              color: 'black' // Set text color to black
            }}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
            required
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '5px',
              fontSize: '16px',
              boxSizing: 'border-box',
              color: 'black' // Set text color to black
            }}
          />
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#000',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontSize: '18px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#333'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#000'}
          >
            Entrar
          </button>
          <p style={{ marginTop: '15px', color: '#777' }}>
            No tienes cuenta?{' '}
            <a
              href="#"
              onClick={() => setIsUserRegistered(false)}
              style={{
                color: '#000',
                textDecoration: 'none',
                fontWeight: 'bold'
              }}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Regístrate aquí
            </a>
          </p>
        </form>
      </div>
    </div>
    </main>
    )}
    { currentComponent === 'workWithUs' && (
      <main style={{
        marginTop: isNavbarExpanded ? "42vh" : "10vh", // Adjust margin based on navbar state
        transition: "margin-top 0.3s ease", // Smooth transition
        width: "100vw", // Make sure the main content takes full width of the viewport
        overflowY: "auto", // Allow overflow for content
      }}>
      <WorkWithUs setCurrentComponent={setCurrentComponent}/>
      </main>
    )}
    { currentComponent === 'yourDelivery' && (
      <main style={{
        marginTop: isNavbarExpanded ? "42vh" : "10vh", // Adjust margin based on navbar state
        transition: "margin-top 0.3s ease", // Smooth transition
        width: "100vw", // Make sure the main content takes full width of the viewport
        overflowY: "auto", // Allow overflow for content
      }}>
      <YourDelivery />
      </main>
    )}
    { currentComponent === 'cobertura' && (
      <main style={{
        marginTop: isNavbarExpanded ? "43vh" : "10vh", // Adjust margin based on navbar state
        transition: "margin-top 0.3s ease", // Smooth transition
        width: "100vw", // Make sure the main content takes full width of the viewport
        overflowY: "auto", // Allow overflow for content
      }}>
      <Cobertura />
      </main>
    )}
      
    </div>
  );
}

export default Main;
