import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

function About() {
    return (
        <div style={{ backgroundColor: 'white', color: 'black', padding: '20px', maxWidth: '100%', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
  <h2 style={{ fontSize: '24px' }}>Zonas de Cobertura</h2>
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
    <ul style={{ listStyle: 'none', padding: '0', columnCount: 2, columnGap: '20px', textAlign: 'left', maxWidth: '800px' }}>
      <li>Barranco</li>
      <li>Chorrillos</li>
      <li>Miraflores</li>
      <li>San Borja</li>
      <li>San Luis</li>
      <li>Santiago de Surco</li>
      <li>Surquillo</li>
      <li>San Juan de Miraflores</li>
      <li>Villa El Salvador</li>
      <li>Villa Maria del Triunfo</li>
      <li>Ate</li>
      <li>Rímac</li>
      <li>Santa Anita</li>
      <li>La Molina</li>
      <li>El Agustino</li>
      <li>San Juan de Lurigancho</li>
      <li>Comas</li>
      <li>Independencia</li>
      <li>Los Olivos</li>
      <li>San Martín de Porres</li>
      <li>Carabayllo</li>
      <li>Puente Piedra</li>
      <li>Ventanilla</li>
      <li>Callao</li>
      <li>Jesús María</li>
      <li>Bellavista</li>
      <li>Lince</li>
      <li>Magdalena del Mar</li>
      <li>Carmen de la Legua</li>
      <li>La Perla</li>
      <li>La Punta</li>
      <li>Cercado de Lima</li>
      <li>San Miguel</li>
      <li>San Isidro</li>
      <li>Pueblo Libre</li>
      <li>La Victoria</li>
      <li>Breña</li>
    </ul>
  </div>
</div>

    )
};

export default About;