import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';
import OrderDriver from './OrderDriver';
import DeliveryDriver from './DeliveryDriver';

function MenuDriver({ user, userId, deliveryPoints, setMainDeliveryPoints, setMainOrder, setActiveComponent, orderPoints, onBackClick }) {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const [deliveries, setDeliveries] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchOrderPoints = async () => {
            
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/driver/${userId}`);
                setOrders(response.data);
                console.log('Fetched order points:', response.data);
            } catch (error) {
                console.error('Error fetching order points:', error);
            }
        };

        fetchOrderPoints();
    }, [userId]);

    useEffect(() => {
        if (orderPoints.length > 0) {
            handlePickupRoutes();
        }
    }, [orderPoints]);

    const handlePickupRoutes = async () => {
        if (orderPoints.length === 0) return;

        const sortedOrderPoints = orderPoints
            .filter(order => order.status === 'encamino')
            .sort((a, b) => a.sequence - b.sequence);

        if (sortedOrderPoints.length === 0) {
            console.log('No pending orders to process.');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                const startPoint = [latitude, longitude];
                const endPoint = [sortedOrderPoints[sortedOrderPoints.length - 1].latitude, sortedOrderPoints[sortedOrderPoints.length - 1].longitude];
                const stops = sortedOrderPoints.map(order => [order.latitude, order.longitude]);

                try {
                    const route = await createRoute(startPoint, endPoint, stops);
                    const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);

                    const updatedOrders = sortedOrderPoints.map((order, index) => ({
                        id: order.id,
                        arrival_time: arrivalTimes[index],
                        timezone: 'America/Lima'
                    }));

                    const token = localStorage.getItem("token");
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/update_arrival_times`, { orders: updatedOrders }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    console.log('Arrival times updated successfully');
                } catch (error) {
                    console.error('Error updating arrival times:', error);
                }
            },
            (error) => {
                console.error('Error getting current location:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
    };

    useEffect(() => {
        const fetchDeliveryPoints = async () => {
            const token = localStorage.getItem('token');
            try { 
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/driver/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDeliveries(response.data);
                console.log('Fetching delivery points:', response.data);
            } catch (error) {
                console.error('Error fetching delivery points:', error);
            }
        }; 
        fetchDeliveryPoints();
    }, [userId]);

    useEffect(() => {
        if (deliveries.length > 0) {
            handleDeliveriesRoutes();
        }
    }, [deliveries]);

    const handleDeliveriesRoutes = async () => {
        if (deliveries.length === 0) {
            console.log('No deliveries available.');
            return;
        } else if (deliveries.length <= 2) {
            console.log('Not enough deliveries to create a route.');
            return;
        }
    
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                const startPoint = [latitude, longitude];
                
                // Filter out deliveries that don't have valid latitude and longitude values
                const sortedDeliveries = deliveries
                    .filter(delivery => delivery.status === 'encamino' && delivery.latitude !== undefined && delivery.longitude !== undefined)
                    .sort((a, b) => a.sequence - b.sequence);
    
                if (sortedDeliveries.length === 0) {
                    console.log('No valid deliveries with latitude and longitude.');
                    return;
                }
    
                const endPoint = [sortedDeliveries[sortedDeliveries.length - 1].latitude, sortedDeliveries[sortedDeliveries.length - 1].longitude];
                const stops = sortedDeliveries.map(delivery => [delivery.latitude, delivery.longitude]);
    
                try {
                    const route = await createRoute(startPoint, endPoint, stops);
                    const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
                    const updatedDeliveries = sortedDeliveries.map((delivery, index) => ({
                        id: delivery.id,
                        arrival_time: arrivalTimes[index],
                        timezone: 'America/Lima'
                    }));
    
                    const token = localStorage.getItem("token");
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/update_arrival_times_deliveries`, { deliveries: updatedDeliveries }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
    
                    console.log('Arrival times updated successfully');
                } catch (error) {
                    console.error('Error updating arrival times:', error);
                }
            },
            (error) => {
                console.error('Error getting current location:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
    };

    const createRoute = async (startPoint, endPoint, stops) => {
        const coordinates = [startPoint, ...stops, endPoint].map(coord => coord.join(',')).join(':');
        const subscriptionKey = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY

        const url = 'https://atlas.microsoft.com/route/directions/json';
        const params = {
            'api-version': '1.0',
            'query': coordinates,
            'subscription-key': subscriptionKey,
            'routeType': 'fastest',
            'optimize': 'time'
        };

        try {
            const response = await axios.get(url, { params });
            return response.data.routes[0];
        } catch (error) {
            console.error('Error creating route:', error);
            throw error;
        }
    };
    

    if (selectedOrder) {
        return <OrderDriver order={selectedDeliveryPoint} onBack={() => setSelectedOrder(null)} onBackClick={onBackClick} />;
    }

    if (selectedDeliveryPoint) {
        return <DeliveryDriver delivery={selectedDeliveryPoint} onBack={() => setSelectedDeliveryPoint(null)} onBackClick={onBackClick} />;
    }

    const statusOrder = {
        pending: 'Agendado',
        encamino: 'En camino',
        recogido: 'Recogido'
    };

    const statusDeliveryPoint = {
        pending: 'Pendiente',
        encamino: 'En ruta',
        entregado: 'Entregado'
    };

    const currentDayDeliveryPoints = deliveryPoints.filter(point => 
        dayjs(point.delivery_date).format('YYYY-MM-DD') === currentDate
    );

    const nextOrder = orders
        .filter(order => !['recogido', 'cancelado'].includes(order.status))
        .sort((a, b) => a.sequence - b.sequence)[0];

    const nextDeliveryPoint = currentDayDeliveryPoints
        .filter(point => !['entregado', 'cancelado', 'pospuesto'].includes(point.status))
        .sort((a, b) => a.sequence - b.sequence)[0];

    const handleDeliveryClick = (delivery) => {
        setMainDeliveryPoints(delivery);
        setActiveComponent('DeliveryDriver');
    };

    const handleOrderClick = (order) => {
        setMainOrder(order);
        setActiveComponent('OrderDriver');
    };

    return (
        <div style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "#000",
        }}>
            <h2>¡Hola {user?.username || 'Conductor'}!</h2>
            {nextOrder ? (
                <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleOrderClick(nextOrder)} key={nextOrder.id}>
                    <AlertTitle>Punto {nextOrder.sequence} Recojo ID: {nextOrder.id} {nextOrder.customer_name} {statusOrder[nextOrder.status]}</AlertTitle>
                    <p>{nextOrder.driver_notes}</p>
                </Alert>
            ) : nextDeliveryPoint ? (
                <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleDeliveryClick(nextDeliveryPoint)} key={nextDeliveryPoint.id}>
                    <AlertTitle>Punto {nextDeliveryPoint.sequence} ID: {nextDeliveryPoint.id} {nextDeliveryPoint.user_name}</AlertTitle>
                    <p>{nextDeliveryPoint.notes}</p>
                </Alert>
            ) : (
                <Alert severity="info" style={{ marginTop: "20px" }}>
                    <AlertTitle>Esperando Ruta.</AlertTitle>
                </Alert>
            )}
        </div>
    );
}

export default MenuDriver;