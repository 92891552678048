import React, { useState } from "react";
import { Navbar, Nav, Container, Carousel } from "react-bootstrap";

function About({ setCurrentComponent }) {

  const handleContactClick = () => {
    const whatsappUrl = `https://wa.me/51922835892`;
    window.open(whatsappUrl, "_blank");
  };
    return (
        <div >
                <div className="row" style={{ padding: '40px', textAlign: 'center' }}>
                    <div className="col-md-7" style={{ paddingLeft: "5%" }}>
                        <h2 className="featurette-heading fw-normal lh-1">
                        Unete a nuestro equipo!
                        </h2>
                        <p className="lead">
                        ¡Únete a nuestro equipo y lleva tus habilidades al siguiente nivel! En Fast Max, estamos buscando conductores experimentados que quieran formar parte de un equipo dinámico y en crecimiento. Ofrecemos la flexibilidad de trabajar a tiempo completo, siendo parte integral de nuestra familia, o si prefieres, puedes optar por trabajar como freelance y elegir cuándo y cuánto trabajar. Cada posición tiene sus propios beneficios, adaptados a las necesidades y estilo de vida de cada conductor. Además, te ofrecemos los pagos más competitivos del mercado, asegurando que tu esfuerzo sea siempre bien recompensado. ¡Forma parte de Fast Max y sé parte del futuro de las entregas rápidas en Lima!                        </p>
                        <a onClick={handleContactClick}>
          <button
            style={{
              backgroundColor: 'rgb(255, 43, 133)',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              borderRadius: '50px', // Rounded corners
              padding: '10px 20px', // Spacing around the text
              fontSize: '1rem',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease' // Smooth transition effect on hover
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(230, 30, 120)')} // Hover effect
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(255, 43, 133)')} // Reset on mouse leave
          >
            Contactanos
          </button>
        </a>
                    </div>
                    
                    <div className="col-md-5" style={{ padding: '10px'}}>
                        <img
                            src={`${process.env.PUBLIC_URL}/anders2.png`}
                            alt="Lima City"
                            className="featurette-image img-fluid mx-auto"
                            width="500"
                            height="500"
                            style={{
                                objectFit: "cover", // Ensures the image fits the container nicely
                                maxHeight: "500px", // Ensure it doesn't exceed the max height
                            }}
                        />
                    </div>
                </div>
                <div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <div class="p-4 md:p-0 w-full">
    </div>
    <footer className="footer bg-black text-white py-4">
  <div className="container" style={{ height: "400px"}}>
    {/* Footer Logo */}
    <div className="footer__logo mb-4 d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
        alt="FastMax Logo"
        width="40"
        height="40"
        className="me-2"
      />
      <span className="fs-4" style={{ color: 'white', fontWeight: 'bold' }}>
        Fast Max
      </span>
    </div>

    {/* Footer Links */}
    <div className="row">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('login')} className="text-white text-decoration-none">
              Ingresa
            </a>
          </li>
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('about')} className="text-white text-decoration-none">
              Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a href="https://www.fastmaxlima.pe/trabaja-con-nosotros" className="text-white text-decoration-none">
              Trabaja con Nosotros
            </a>
          </li>
          <li className="mb-2">
            <a onClick={() => setCurrentComponent('yourDelivery')} className="text-white text-decoration-none">
              Tu Entrega
            </a>
          </li>
          <li className="mb-2">
            <a href="https://www.fastmaxlima.pe/contacto" className="text-white text-decoration-none">
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="row mt-4">
      <div className="col-md-6">
        <ul className="list-unstyled">
          <li>
            Copyright © 2024{' '}
            <a href="https://www.fastmaxlima.pe" target="_blank" className="text-white text-decoration-none">
              Fast Max
            </a>
          </li>
        </ul>
      </div>
      <div className="col-md-6 text-md-end">
        <ul className="list-unstyled">
          <li>
            <a
              href="https://www.fastmaxlima.pe/politica-de-privacidad"
              className="text-white text-decoration-none"
            >
              Política de Privacidad
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
            </div>
    )
};

export default About;