import React, { useState, useEffect } from "react";
import axios from 'axios';
import MapDriver from "./MapDriver";
import OptionsDriver from "./OptionsDriver";
import MyAccountDriver from "./MyAccountDriver";
import MapSelectionDriver from "./MapSelectionDriver";
import OrderList from "./OrderList";
import DeliveryList from "./DeliveryList";
import OrderDriver from "./OrderDriver";
import DeliveryDriver from "./DeliveryDriver";
import ResumenDriver from "./ResumenDriver";
import WorkDriver from "./WorkDriver";

function MainDriver({ user_id, username, onLogout, email, phone_number, onUpdateUser }) {
    const [activeComponent, setActiveComponent] = useState('Map');
    const [mainDeliveryPoints, setMainDeliveryPoints] = useState([]);
    const [mainOrder, setMainOrder] = useState(null);
    
    
    useEffect(() => {
        let intervalId;
        const token = localStorage.getItem("token");

        if (navigator.geolocation) {
            intervalId = setInterval(() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    fetch(`${process.env.REACT_APP_API_URL}/api/driver/location`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`, // Add the token to the request headers
                        },
                        body: JSON.stringify({
                            driver_id: user_id,
                            latitude,
                            longitude,
                        }),
                    });
                });
            }, 3000);
    }

        // Cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [user_id]);

    // useEffect(() => {
    //     let intervalId;
    //     const token = localStorage.getItem("token");
      
    //     // Function to generate a random point within a given radius (in meters)
    //     const getRandomCoordinates = (latitude, longitude, radius) => {
    //       const earthRadius = 6371e3; // Earth's radius in meters
      
    //       // Convert latitude and longitude from degrees to radians
    //       const lat = latitude * Math.PI / 180;
    //       const lon = longitude * Math.PI / 180;
      
    //       // Random distance and bearing
    //       const dist = Math.sqrt(Math.random()) * radius;
    //       const bearing = Math.random() * 2 * Math.PI;
      
    //       // Convert distance and bearing to latitude and longitude
    //       const newLat = Math.asin(Math.sin(lat) * Math.cos(dist / earthRadius) +
    //                     Math.cos(lat) * Math.sin(dist / earthRadius) * Math.cos(bearing));
    //       const newLon = lon + Math.atan2(Math.sin(bearing) * Math.sin(dist / earthRadius) * Math.cos(lat),
    //                                      Math.cos(dist / earthRadius) - Math.sin(lat) * Math.sin(newLat));
      
    //       // Convert new coordinates from radians to degrees
    //       return {
    //         latitude: newLat * 180 / Math.PI,
    //         longitude: newLon * 180 / Math.PI
    //       };
    //     };
      
    //     intervalId = setInterval(() => {
    //       const { latitude, longitude } = getRandomCoordinates(-12.046374, -77.042793, 5000); // 5 km radius
      
    //       fetch(`${process.env.REACT_APP_API_URL}/api/driver/location`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': `Bearer ${token}`,
    //         },
    //         body: JSON.stringify({
    //           driver_id: user_id,
    //           latitude,
    //           longitude,
    //         }),
    //       });
    //     }, 5000);
    
    //     // Cleanup function
    //     return () => {
    //         if (intervalId) {
    //             clearInterval(intervalId);
    //         }
    //     };
    // }, [user_id]);

    const handleEntregasClick = () => {
        console.log('entrega');
        setActiveComponent('DeliveryList');
    };
    
    const handleRecojosClick = () => {
        console.log('recojo');
        setActiveComponent('OrderList');
    };


    const handleBackClick = () => {
        setActiveComponent('Map');
         
    };
    
    const handleMenuClick = () => {
        setActiveComponent('OptionsDriver');
    };

    const handleOptionClick = (option) => {
        if (option === 'back') {
            setActiveComponent(activeComponent === 'MapDriver' ? 'driver' : 'MapDriver');
        } else if (option === 'MapSelectionDriver') {
            setActiveComponent('MapSelectionDriver');
        } else if (option === 'OrderList') {
            setActiveComponent('OrderList')
        } else if (option === 'DeliveryList') {
            setActiveComponent('DeliveryList')
        } else if (option === 'OptionsDriver') {
            setActiveComponent('OptionsDriver')
        } else if (option === 'driver') {
            setActiveComponent('driver')
        } else if (option === 'WorkDriver') {
            setActiveComponent('WorkDriver')
        }

        
        
        else {
            setActiveComponent('MyAccountDriver')
            // Handle other options
            
        }
    };
    
    return (
        <div className="container-fluid p-0">
            {activeComponent === 'Map' && 
            <div className="map-container">
                
                    <MapDriver setActiveComponent={setActiveComponent} handleRecojosClick={handleRecojosClick} handleEntregasClick={handleEntregasClick} userId={user_id} setMainDeliveryPoints={setMainDeliveryPoints} setMainOrder={setMainOrder}/>
                     
            </div>
            }
            {activeComponent === 'driver' && 
            <div className="map-container">
                
                    <MapDriver activeComponent={activeComponent} handleMenuClick={handleMenuClick} onOptionClick={handleOptionClick} userId={user_id}/>
                     
            </div>
            }
            {activeComponent === 'Options' && 
                <OptionsDriver username={username} onOptionClick={handleOptionClick} onBackClick={handleBackClick} onLogout={onLogout} />
            }
            {activeComponent === 'MyAccountDriver' &&
                <MyAccountDriver onUpdateUser={onUpdateUser} userId={user_id} username={username} phoneNumber={phone_number} email={email} onOptionClick={handleOptionClick} onBackClick={handleBackClick} onLogout={onLogout}/>
            }
            {activeComponent === 'MapSelectionDriver' &&
                <MapSelectionDriver onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id} />
            }
            {activeComponent === 'WorkDriver' &&
                <WorkDriver onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id} />
            }
            {activeComponent === 'OrderList' &&
                <OrderList onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'DeliveryList' &&
                <DeliveryList activeComponent={activeComponent} onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'DeliveryDriver' &&
                <DeliveryDriver delivery={mainDeliveryPoints} onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'OrderDriver' &&
                <OrderDriver order={mainOrder} onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'ResumenDriver' &&
                <ResumenDriver onBackClick={handleBackClick} userId={user_id}/>
            }

        </div>
    );
}

export default MainDriver;