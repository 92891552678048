import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { TextField, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import MyLocationIcon from '@mui/icons-material/MyLocation';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function MapSelectionDriver({ onBackClick, userId }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const currentMarkerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const ConfirmModal = ({ message, onConfirm, onCancel }) => (
    <div className="modal-overlay-entrega">
      <div 
        className="modal-content"
        style={{
          backgroundColor: 'white',   // Set modal background to white
          padding: '20px',            // Add padding for a clean look
          borderRadius: '8px',        // Optional: Add border radius for smooth corners
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Optional: Add shadow for depth
        }}
      >
        <div 
          className="modal-message"
          style={{
            color: 'black',          // Make font color black
            fontWeight: 'bold',      // Make text a bit bolder
            fontSize: '16px',        // Optional: Adjust font size if needed
            marginBottom: '20px'     // Optional: Add space below the message
          }}
        >
          {message}
        </div>
        <div className="modal-actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            className="modal-ok-button"
            onClick={onConfirm}
            style={{
              backgroundColor: 'rgb(255, 43, 133)',  // Optional: Set a color for the button
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            OK
          </button>
          <button
            className="modal-cancel-button"
            onClick={onCancel}
            style={{
              backgroundColor: 'grey', // Optional: Color for the cancel button
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  

    const handleConfirmClick = () => {
      if ( !selectedLocation) {
        setErrorMessage("Todos los campos obligatorios deben completarse.");
        return;
      }

      setErrorMessage("");
      setIsModalOpen(true);
    };



  //   useEffect(() => {
  //     alert("Escoge una ubicación precisa de recojo");
  // }, []);

  
  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      let mapCenter = [-77.03711992646711, -12.060235303763926];
      let zoomLevel = 12;

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: mapCenter,
        zoom: zoomLevel,
      });

      mapRef.current.on('load', () => {
        console.log('Map is ready');
      });
      alert("Escoge una ubicación precisa de recojo");
      mapRef.current.on('click', (e) => {
        const { lng, lat } = e.lngLat;
        addMarker(lng, lat);
        setSelectedLocation({ lat, lon: lng });
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [userId]);

  const addMarker = (lng, lat) => {
    if (currentMarkerRef.current) {
      currentMarkerRef.current.remove();
    }

    currentMarkerRef.current = new mapboxgl.Marker({ color: 'red' })
      .setLngLat([lng, lat])
      .addTo(mapRef.current);
  };
  const handleSearch = async (query) => {
    if (query.length > 2) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
                params: { query },
            });
            setSearchResults(response.data.results);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    } else {
        setSearchResults([]);
    }
};

const handleResultClick = (result) => {
    const { lat, lon } = result.position;
    setSelectedLocation({ lat, lon });
    setSearchResults([]); // Clear the search results

    // Add new marker
    addMarker(lon, lat);

    mapRef.current.flyTo({
        center: [lon, lat],
        zoom: 17,
    });
};

  const handleGetCurrentLocations = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setSelectedLocation({ lat: latitude, lon: longitude });
          addMarker(longitude, latitude);
          mapRef.current.flyTo({
            center: [longitude, latitude],
            zoom: 16,
          });
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const handleSave = async () => {
    setIsModalOpen(false);
    
    // Assuming you have an array of addresses and a selected location
    if (selectedLocation) {
        try {
            // Construct the new address object
            const newAddress = {
                latitude: selectedLocation.lat,  // The latitude of the selected location
                longitude: selectedLocation.lon  // The longitude of the selected location
            };

            // Send the new address along with existing addresses (if any)
            await axios.put(`${process.env.REACT_APP_API_URL}/api/driver_final_location`, {
                addresses: [newAddress],  // Send the addresses array, could include more addresses if needed
                driver_id: userId
              });
            
            onBackClick();  // Call the function to go back after saving
        } catch (error) {
            console.error('Error saving location:', error);
        }
    }
};

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    handleSearch(event.target.value);
};
const handleCancel = () => {
  setIsModalOpen(false);
};

  return (
    <div style={{ height: "100vh", position: "relative" }}>
  
      <div
        ref={mapContainerRef}
        style={{ height: "60vh" }}
      ></div>
      
      <div className="search-container-user">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Busca tu dirección"
                className="search-input"
              />
              {searchResults.length > 0 && (
                <div className="search-results">
                  {searchResults.map((result) => (
                    <div
                      key={result.id}
                      onClick={() => handleResultClick(result)}
                      className="search-result"
                    >
                      {result.address.freeformAddress}
                    </div>
                  ))}
                </div>
              )}
            </div>
      <IconButton
        style={{
          position: "absolute",
          bottom: "50%",
          right: 20,
          zIndex: 1000,
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "50%",
          color: "black",
        }}
        onClick={handleGetCurrentLocations}
      >
        <MyLocationIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "91%",
          right: 20,
          zIndex: 1000,
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "50%",
          color: "black",
        }}
        onClick={onBackClick}
      >
        <ArrowBackIcon />
      </IconButton>

      <div
        style={{
          height: "40vh",
          padding: "20px",
          backgroundColor: "#f7f7f7",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "#000",
          }}
        >
          
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button
            onClick={handleConfirmClick}
            style={{
              display: "block",
              width: "80%",
              margin: "0 auto",
              padding: "10px 20px",
              backgroundColor: "#616569",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Guardar
          </button>
          {isModalOpen && (
                        <ConfirmModal
                            message="¿Está seguro de que la ubicacion y direccion ingresada es correcta?"
                            onConfirm={handleSave}
                            onCancel={handleCancel}
                        />
                    )}
        </div>
      </div>
    </div>
  );
}

export default MapSelectionDriver;


