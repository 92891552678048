import React, { useState } from "react";
import axios from 'axios';
import MapAdmin from "./MapAdmin";
import OptionsAdmin from "./OptionsAdmin";
import MyAccounAdmin from "./MyAccountAdmin";   
import OrderListAdmin from "./OrderListAdmin";
import DeliveryListAdmin from "./DeliveryListAdmin";
import SearchGlobal from "./SearchGlobal";
import ResumenAdmin from "./ResumenAdmin";
import Mensajeria from "./Mensajeria";
import Location from "./Location";

function MainAdmin({ user_id, username, onLogout, email, phone_number, onUpdateUser }) {
    const [activeComponent, setActiveComponent] = useState('MapAdmin');
    
    

    const handleBackClick = () => {
        setActiveComponent('MapAdmin');
         
    };
    
    const handleMenuClick = () => {
        setActiveComponent('OptionsAdmin');
    };

    const handleOptionClick = (option) => {
        if (option === 'back') {
            setActiveComponent(activeComponent === 'MapAdmin' ? 'admin' : 'MapAdmin');
        } else if (option === 'MapSelectionAdmin') {
            setActiveComponent('MapSelectionAdmin');
        } else if (option === 'OrderListAdmin') {
            setActiveComponent('OrderListAdmin')
        } else if (option === 'DeliveryListAdmin') {
            setActiveComponent('DeliveryListAdmin')
        } else if (option === 'OptionsAdmin') {
            setActiveComponent('OptionsAdmin')
        } else if (option === 'admin') {
            setActiveComponent('admin')
        } else if (option === 'SearchGlobal') {
            setActiveComponent('SearchGlobal')
        } else if (option === 'ResumenAdmin') {
            setActiveComponent('ResumenAdmin')
        }

        
        
        else {
            setActiveComponent('MyAccountAdmin')
            // Handle other options
            
        }
    };

    return (
        <div className="container-fluid p-0">
            {activeComponent === 'MapAdmin' && 
            <div className="map-container">
                
                    <MapAdmin setActiveComponent={setActiveComponent} handleMenuClick={handleMenuClick} onOptionClick={handleOptionClick} userId={user_id}/>
                     
            </div>
            }
            {activeComponent === 'OptionsAdmin' && 
                <OptionsAdmin username={username} onOptionClick={handleOptionClick} onBackClick={handleBackClick} onLogout={onLogout} />
            }
            {activeComponent === 'MyAccountAdmin' &&
                <MyAccounAdmin onUpdateUser={onUpdateUser} userId={user_id} username={username} phoneNumber={phone_number} email={email} onOptionClick={handleOptionClick} onBackClick={handleBackClick} />
            }
            {activeComponent === 'OrderListAdmin' &&
                <OrderListAdmin onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'DeliveryListAdmin' &&
                <DeliveryListAdmin activeComponent={activeComponent} onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id}/>
            }
            {activeComponent === 'SearchGlobal' &&

                <SearchGlobal onBackClick={handleBackClick}/>
            }
            {activeComponent === 'ResumenAdmin' &&
                <ResumenAdmin onBackClick={handleBackClick}/>    

            }
            {activeComponent === 'Mensajeria' &&
                <Mensajeria onBackClick={handleBackClick}/> 

            }
            {activeComponent === 'Location' &&
                <Location onBackClick={handleBackClick}/> 
            }   
        </div>
    );
}

export default MainAdmin;